import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { makeThemedStyles } from '@provider-hooks';

export interface Props {
  style?: React.CSSProperties;
  track: any;
  onMount?: (element: HTMLVideoElement) => void;
  onUnmount?: (element: HTMLVideoElement) => void;
}

const VideoTrack: React.FC<Props> = ({ style, track, onMount, onUnmount }) => {
  const ref = useRef<HTMLVideoElement>(null);
  const { styles } = useStyles();

  useEffect(() => {
    if (track && ref.current) {
      const element = ref.current as HTMLVideoElement;
      onMount && onMount(element);
      track.attach(element);

      return () => {
        onUnmount && onUnmount(element);
        track.detach(element);
      };
    }
  }, [ref, track]);

  useEffect(() => {
    if (ref.current) {
      const el = ref.current;
      el.addEventListener('pause', () => {
        el.play();
      });
    }
  }, [ref]);

  return (
    <Box sx={styles.container}>
      <video style={{ ...style }} ref={ref} data-testid='video' />
    </Box>
  );
};

const useStyles = makeThemedStyles()(_ => ({
  container: {
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    aspectRatio: '16/9',
    '&>video': {
      width: 'auto',
      //sometimes the timestamp line falls out of the hidden area (extra 0.5% fixing this issue)
      maxWidth: '101%',
      minWidth: '101%'
    }
  }
}));

export default VideoTrack;
