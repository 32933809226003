import { useWebsocketHelper } from 'src/wsHelper';
import { useCallback } from 'react';
import { EBinaryWebsocketAudioFormat, EWebsocketAudioEvent } from 'src/@types/enums';
import { pack as msgpack } from 'msgpackr';

interface UseCommonWSMessages {
  sendAudioToTranscribe: (
    audio: ArrayBuffer,
    audioFormat: EBinaryWebsocketAudioFormat,
    wsEvent: EWebsocketAudioEvent
  ) => void;
}

// Used to handle binary messages sent to the CMS. Currently just for sending caption or
// AI scribe audio.
export const useCommonWSMessages = (callId: string): UseCommonWSMessages => {
  const { sendBinaryWsMessage } = useWebsocketHelper();

  // Function to create and memoize the header blob
  const createHeaderBlob = useCallback(
    (audioFormat: EBinaryWebsocketAudioFormat, wsEvent: string) => {
      const memoizedHeaders: Record<string, Blob> = {};

      return () => {
        const memoKey = `${callId}-${audioFormat}-${wsEvent}`;

        // avoid recomputing if we've previously packed this header info
        // for the given call
        if (memoizedHeaders[memoKey]) {
          return memoizedHeaders[memoKey];
        }

        const header = {
          event: wsEvent,
          sampleRateHz: 16000,
          format: audioFormat,
          callUuid: callId
        };
        const packedHeader = msgpack(header);
        const packedHeaderLength = packedHeader.length;
        const headerBuffer = new ArrayBuffer(2 + packedHeaderLength);
        const headerView = new DataView(headerBuffer);
        headerView.setUint16(0, packedHeaderLength, false);
        for (let i = 0; i < packedHeaderLength; i++) {
          headerView.setUint8(i + 2, packedHeader[i]);
        }
        const headerBlob = new Blob([headerBuffer]);

        memoizedHeaders[memoKey] = headerBlob;
        return headerBlob;
      };
    },
    [callId]
  );

  // Send 16-bit, little endian 16KHz PCM audio chunks to the CMS
  // (or, webm - depends on the header)
  const sendAudioToTranscribe = useCallback(
    async (
      rawAudio: ArrayBuffer,
      audioFormat: EBinaryWebsocketAudioFormat,
      wsEvent: EWebsocketAudioEvent
    ): Promise<void> => {
      const rawAudioBlob = new Blob([rawAudio]);
      const headerBlobFn = createHeaderBlob(audioFormat, wsEvent);
      const bufToSend = new Blob([headerBlobFn(), rawAudioBlob]);

      return sendBinaryWsMessage(bufToSend);
    },
    [createHeaderBlob, sendBinaryWsMessage]
  );

  return {
    sendAudioToTranscribe
  };
};
