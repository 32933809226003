import { createSelector } from 'reselect';
import { EsitterInitialState } from '@provider-types/reducer';
import { RootState } from '@provider-reducers/root';
import { IDeviceRoomInfo } from '@provider-types/provider';
import {
  DeviceRebootState,
  EPrerecordedMessageGender,
  EPrerecordedMessageLang,
  ETagColor
} from '@provider-types/enums';
import { selectIsMicrophoneOk } from '../mediaPermissions';
import { AUDIO_LEVEL_AVAILABLE_ID, PRERECORDED_MESSAGES_AVAILABLE_ID } from '@provider-constants';
import { getAvailbleGendersForLang } from '@provider-utils/redux/prerecordedMessages';

import { HealthDataState } from '@provider-reducers/healthDataSlice';

export const getHealthData = (state: RootState): HealthDataState => state.healthData;
export const getHealthDataByDeviceid = createSelector(
  [getHealthData, (healthData, deviceId) => deviceId],
  (healthData, deviceId) => {
    return (
      healthData.healthDataArray.find(value => value.deviceId === deviceId) || {
        deviceId: ''
      }
    );
  }
);

export const getEsitter = (state: RootState): EsitterInitialState => state.esitter;
export const selectRoomsArray = createSelector([getEsitter], esitter => esitter.roomsArray);
export const selectIsRoomsArrayEmpty = createSelector([getEsitter], esitter => esitter.roomsArray.length === 0);
export const selectModalVisible = createSelector([getEsitter], esitter => esitter.modalVisible);

export const selectModalData = createSelector([getEsitter], esitter => esitter.modalData);
export const selectPatientDetailsVisible = createSelector([getEsitter], esitter => esitter.patientDetailsVisible);
export const selectSidePanelCollapsed = createSelector([getEsitter], esitter => esitter.sidePanelCollapsed);

export const selectSortType = createSelector([getEsitter], esitter => esitter.sortType);
export const selectIsPendingRemoval = createSelector([getEsitter], esitter => esitter.isPendingRemoval);
export const selectIsCameraControlActive = createSelector([getEsitter], esitter => esitter.isCameraControlActive);

export const selectIsAudioControlActive = createSelector([getEsitter], esitter => esitter.isAudioControlActive);
export const selectHoveredDeviceId = createSelector([getEsitter], esitter => esitter.hoveredDeviceId);
export const selectIsRoomLoading = createSelector([getEsitter], esitter => esitter.isRoomLoading);

export const selectIsRedirectDisabled = createSelector([getEsitter], esitter => esitter.isRedirectDisabled);

export const selectIsInterventionConnecting = createSelector(
  [getEsitter],
  esitter => esitter.interventionConnectionStatus.connecting
);

export const selectIsListenModeConnecting = createSelector(
  [getEsitter],
  esitter => esitter.listenModeConnectionStatus.connecting
);

export const selectIsInterventionDisconnecting = createSelector(
  [getEsitter],
  esitter => esitter.interventionConnectionStatus.disconnecting
);

export const selectIsListenModeDisconnecting = createSelector(
  [getEsitter],
  esitter => esitter.listenModeConnectionStatus.disconnecting
);

export const selectInterventionConnectionDeviceId = createSelector(
  [getEsitter],
  esitter => esitter.interventionConnectionStatus.deviceId
);

export const selectListenModeConnectionDeviceId = createSelector(
  [getEsitter],
  esitter => esitter.listenModeConnectionStatus.deviceId
);

export const selectDeviceName = createSelector([getEsitter, (esitter, deviceId) => deviceId], (esitter, deviceId) => {
  const { deviceName } = esitter.roomsArray.find(item => item.deviceId === deviceId) || { deviceName: deviceId };
  return deviceName;
});

export const selectSidePanelContentName = createSelector([getEsitter], esitter => esitter.contentName);

export const selectRefererRemovalTab = createSelector([getEsitter], esitter => esitter.refererRemovalTab);

export const selectDeviceById = createSelector([getEsitter, (esitter, deviceId) => deviceId], (esitter, deviceId) => {
  return esitter.roomsArray.find(item => item.deviceId === deviceId);
});

export const getSelectedDeviceId = createSelector([getEsitter], esitter => esitter.selectedDevice?.deviceId || '');

export const selectInteractiveDevice = createSelector([getEsitter], esitter => {
  return esitter.roomsArray.find((room: IDeviceRoomInfo) => room.mode.includes('interactive'));
});

export const selectInteractiveDeviceId = createSelector([getEsitter], esitter => {
  return esitter.roomsArray.find((room: IDeviceRoomInfo) => room.mode.includes('interactive'))?.deviceId;
});

export const selectIsDeviceInInteractiveMode = createSelector(
  [selectInteractiveDeviceId, (interactiveDeviceId, deviceId) => deviceId],
  (interactiveDeviceId, deviceId) => {
    return interactiveDeviceId && deviceId ? Boolean(interactiveDeviceId === deviceId) : false;
  }
);

export const selectIsAnotherDeviceInInteractiveMode = createSelector(
  [selectInteractiveDeviceId, (interactiveDeviceId, deviceId) => deviceId],
  (interactiveDeviceId, deviceId) => {
    return interactiveDeviceId && deviceId ? Boolean(interactiveDeviceId !== deviceId) : false;
  }
);

export const selectIsSelectedDeviceInInteractiveMode = createSelector(
  [selectInteractiveDeviceId, getSelectedDeviceId],
  (interactiveDeviceId, selectedDeviceId) => {
    return interactiveDeviceId && selectedDeviceId ? Boolean(interactiveDeviceId === selectedDeviceId) : false;
  }
);

export const selectDeviceSelectedCameraUuid = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { selectedCameraUuid } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      selectedCameraUuid: ''
    };
    return selectedCameraUuid;
  }
);

export const selectDeviceMicGain = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { micGain } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      micGain: 0
    };
    return micGain;
  }
);

export const selectDeviceMicGainLimit = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { micGainLimit } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      micGainLimit: { min: true, max: true }
    };
    return micGainLimit;
  }
);

export const selectDevicePatientVolume = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { patientVolume } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      patientVolume: 0
    };
    return patientVolume;
  }
);

export const selectDeviceZoom = createSelector([getEsitter, (esitter, deviceId) => deviceId], (esitter, deviceId) => {
  const { zoom } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
    zoom: 0
  };
  return zoom;
});

export const selectDevicePanLimit = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { panLimit } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      panLimit: { min: true, max: true }
    };
    return panLimit;
  }
);

export const selectDeviceTiltLimit = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { tiltLimit } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      tiltLimit: { min: true, max: true }
    };
    return tiltLimit;
  }
);

export const selectDeviceZoomLimit = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { zoomLimit } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      zoomLimit: { min: true, max: true }
    };
    return zoomLimit;
  }
);

export const selectDeviceCameraList = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { cameraList } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      cameraList: []
    };
    return cameraList;
  }
);

export const selectDevicePatientVolumeLimit = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { patientVolumeLimit } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.deviceInfo || {
      patientVolumeLimit: { min: true, max: true }
    };
    return patientVolumeLimit;
  }
);

export const selectDeviceTwilioRoom = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { room } = esitter.roomsArray.find(item => item.deviceId === deviceId) || {
      room: ''
    };
    return room;
  }
);

export const selectDeviceTwilioToken = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { token } = esitter.roomsArray.find(item => item.deviceId === deviceId) || {
      token: ''
    };
    return token;
  }
);

export const selectIsDeviceInPrivacyMode = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.some(
      (room: IDeviceRoomInfo) => room.deviceId === deviceId && room.deviceInfo.privacyMode
    );
  }
);

export const selectIsDevicePaused = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.some((room: IDeviceRoomInfo) => room.deviceId === deviceId && room.paused);
  }
);

export const selectRoomAudioLevel = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.find((room: IDeviceRoomInfo) => room.deviceId === deviceId)?.audioLevel || 0;
  }
);

export const selectRoomMessageGender = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return (
      esitter.roomsArray.find((room: IDeviceRoomInfo) => room.deviceId === deviceId)?.messageGender ||
      EPrerecordedMessageGender.MALE
    );
  }
);

export const selectRoomMessageLang = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return (
      esitter.roomsArray.find((room: IDeviceRoomInfo) => room.deviceId === deviceId)?.messageLang ||
      EPrerecordedMessageLang.ENGLISH
    );
  }
);

export const selectDeviceTimeDelay = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.find((room: IDeviceRoomInfo) => room.deviceId === deviceId)?.deviceTimeDelay || 0;
  }
);

export const selectAvailableRoomMessageGenderByLang = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return getAvailbleGendersForLang({ esitter, deviceId });
  }
);

export const selectRoomMessagePlaying = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.find((room: IDeviceRoomInfo) => room.deviceId === deviceId)?.messagePlaying;
  }
);

export const selectMessageList = createSelector(getEsitter, esitter => {
  return esitter.messages || [];
});

export const selectAvailableMessagesLang = createSelector(getEsitter, esitter => {
  return [...new Set((esitter.messages || []).map(message => message.lang))];
});

export const selectIsDeviceFlipped = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.some(
      (room: IDeviceRoomInfo) => room.deviceId === deviceId && room.deviceInfo.videoRotationAngle !== 0
    );
  }
);

export const selectDeviceTagColor = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { tagColor } = esitter.roomsArray.find(item => item.deviceId === deviceId) || {
      tagColor: ETagColor.NONE
    };
    return tagColor;
  }
);

export const selectIsDebugModeOn = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { debugModeOn } = esitter.roomsArray.find(item => item.deviceId === deviceId) || {
      debugModeOn: false
    };

    return debugModeOn;
  }
);

export const selectDebugShapeType = createSelector(
  [getEsitter, selectDeviceSelectedCameraUuid, (esitter, deviceId) => deviceId],
  (esitter, cameraUuid, deviceId) => {
    const { debugShapes } = esitter.roomsArray.find(item => item.deviceId === deviceId) || {
      debugShapes: []
    };
    const selectedShape = debugShapes?.find(ds => ds.cameraUuid === cameraUuid);
    return selectedShape?.type || null;
  }
);

export const selectDebugPolygonPoints = createSelector(
  [getEsitter, selectDeviceSelectedCameraUuid, (esitter, deviceId) => deviceId],
  (esitter, cameraUuid, deviceId) => {
    const { debugShapes } = esitter.roomsArray.find(item => item.deviceId === deviceId) || {
      debugShapes: []
    };
    const selectedShape = debugShapes?.find(ds => ds.cameraUuid === cameraUuid && ds.type === 'polygon');

    if (selectedShape && selectedShape.type === 'polygon') {
      return selectedShape.shapeData.points;
    }

    return [];
  }
);

export const selectDebugLineSegments = createSelector(
  [getEsitter, selectDeviceSelectedCameraUuid, (esitter, deviceId) => deviceId],
  (esitter, cameraUuid, deviceId) => {
    const { debugShapes } = esitter.roomsArray.find(item => item.deviceId === deviceId) || {
      debugShapes: []
    };

    return (
      debugShapes
        ?.filter(shape => shape.cameraUuid === cameraUuid && shape.type === 'lineSegments')
        .map(shape => {
          // check for TS
          if (shape.type === 'lineSegments') {
            return shape.shapeData.segments;
          } else {
            return [];
          }
        }) ?? []
    );
  }
);

export const selectDevicePatientName = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { name } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.patientInfo || {
      name: ''
    };
    return name;
  }
);
export const selectDevicePatientReasonForMonitoring = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { reasonForMonitoring } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.patientInfo || {
      reasonForMonitoring: ''
    };
    return reasonForMonitoring;
  }
);
export const selectDevicePatientLocation = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { location } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.patientInfo || {
      location: ''
    };
    return location;
  }
);
export const selectDevicePatientLanguage = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { language } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.patientInfo || {
      language: ''
    };
    return language;
  }
);

export const selectDevicePatientPrimaryContact = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    const { primaryContact } = esitter.roomsArray.find(item => item.deviceId === deviceId)?.patientInfo || {
      primaryContact: ''
    };
    return primaryContact;
  }
);

export const selectHasReceivedWSMessage = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.some(
      (room: IDeviceRoomInfo) => room?.deviceId === deviceId && room?.hasReceivedWSMessage
    );
  }
);

export const getCallId = createSelector([getEsitter], esitter => esitter.callId);
export const selectSelectedDeviceSelectedCameraUuid = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.deviceInfo.selectedCameraUuid || ''
);

export const selectSelectedDeviceSelectedCameraName = createSelector(
  [getEsitter, selectSelectedDeviceSelectedCameraUuid],
  (esitter, selectedCameraUuid) => {
    return esitter.selectedDevice?.deviceInfo?.cameraList?.find(c => c.uuid === selectedCameraUuid)?.name ?? '';
  }
);

export const getSelectedDevice = createSelector([getEsitter], esitter => esitter.selectedDevice);
export const getSelectedDeviceName = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.deviceName || esitter.selectedDevice?.deviceId
);
export const selectSelectedDeviceFacility = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.facility || ''
);

export const getSelectedDeviceNotes = createSelector([getEsitter], esitter => esitter.selectedDevice?.note || '');

export const selectIsSmartRailsSupportedForSelectedDevice = createSelector(
  [getEsitter],
  esitter =>
    esitter.selectedDevice?.deviceCapabilities?.find(capabilityItem => capabilityItem.id === 1000)?.available || false
);

export const selectIsAudioLevelSupportedForDevice = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return (
      esitter.roomsArray
        .find((room: IDeviceRoomInfo) => room.deviceId === deviceId)
        ?.deviceCapabilities?.find(capabilityItem => capabilityItem.id === AUDIO_LEVEL_AVAILABLE_ID)?.available || false
    );
  }
);

export const selectAreMessagesSupportedForDevice = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return (
      esitter.roomsArray
        .find((room: IDeviceRoomInfo) => room.deviceId === deviceId)
        ?.deviceCapabilities?.find(capabilityItem => capabilityItem.id === PRERECORDED_MESSAGES_AVAILABLE_ID)
        ?.available || false
    );
  }
);

export const isDeviceSelected = createSelector(
  [getSelectedDeviceId, (selectedDeviceId, deviceId) => deviceId],
  (selectedDeviceId, deviceId) => {
    return selectedDeviceId === deviceId;
  }
);

export const selectSelectedDeviceInPrivacyMode = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.deviceInfo?.privacyMode || false
);
export const selectSelectedDevicePaused = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.paused || false
);
export const selectIsSelectedDeviceRebooting = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.deviceRebootState === DeviceRebootState.REBOOT || false
);

export const selectSelectedDeviceCanStartIntervention = createSelector(
  [
    selectSelectedDeviceInPrivacyMode,
    selectSelectedDevicePaused,
    selectIsMicrophoneOk,
    selectIsSelectedDeviceRebooting
  ],
  (privacyMode, paused, IsMicrophoneOk, isRebooting) => {
    return IsMicrophoneOk && !privacyMode && !paused && !isRebooting;
  }
);

export const selectDeviceCanStartIntervention = createSelector(
  [
    (state, deviceId) => selectIsDeviceInPrivacyMode(state, deviceId),
    (state, deviceId) => selectIsDevicePaused(state, deviceId),
    selectIsMicrophoneOk
  ],
  (privacyMode, paused, isMicrophoneOk) => {
    return isMicrophoneOk && !privacyMode && !paused;
  }
);

export const selectSelectedDevicePatientName = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.patientInfo?.name || ''
);
export const selectSelectedDevicePatientReasonForMonitoring = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.patientInfo?.reasonForMonitoring || ''
);
export const selectSelectedDevicePatientLocation = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.patientInfo?.location || ''
);
export const selectSelectedDevicePatientLanguage = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.patientInfo?.language || ''
);
export const selectSelectedDevicePatientPrimaryContact = createSelector(
  [getEsitter],
  esitter => esitter.selectedDevice?.patientInfo?.primaryContact || ''
);

export const selectDeviceByCameraUuid = createSelector(
  [getEsitter, (esitter, cameraUuid) => cameraUuid],
  (esitter, cameraUuid) => {
    return esitter.roomsArray.find(d => d.deviceInfo.selectedCameraUuid === cameraUuid);
  }
);

export const selectListenModeDevicesStorageWhileIntervention = createSelector(
  [getEsitter],
  esitter => esitter.listenModeDevicesStorageWhileIntervention ?? []
);

export const selectListenModeDevices = createSelector([getEsitter], esitter => {
  return esitter.roomsArray.filter((room: IDeviceRoomInfo) => room.mode.includes('observation_av'));
});

export const selectListenModeDevicesIds = createSelector([getEsitter], esitter => {
  return esitter.roomsArray
    .filter((room: IDeviceRoomInfo) => room.mode.includes('observation_av'))
    .map(device => device.deviceId);
});

export const selectIsDeviceInListenMode = createSelector(
  [selectListenModeDevicesIds, (listenModeDevicesIds, deviceId) => deviceId],
  (listenModeDevicesIds, deviceId) => {
    return listenModeDevicesIds.length && deviceId ? listenModeDevicesIds.includes(deviceId) : false;
  }
);
export const selectSelectedDeviceAlarm = createSelector([selectDeviceById, (esitter, deviceId) => deviceId], device => {
  return device?.alarmStatus || null;
});

export const selectFocusedRoom = createSelector([getEsitter], esitter => esitter.focusedRoom || undefined);

export const selectBlurModeStatus = createSelector(
  [selectDeviceById, (esitter, device) => device],
  device => device?.blurModeStatus || null
);

export const selectIsMicActive = createSelector([getEsitter, (esitter, deviceId) => deviceId], (esitter, deviceId) => {
  return esitter.roomsArray.find(room => room.deviceId === deviceId)?.isEsitterLocalMicActive ?? false;
});

export const selectDeviceRebootState = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return esitter.roomsArray.find(room => room.deviceId === deviceId)?.deviceRebootState || DeviceRebootState.ALIVE;
  }
);

export const selectIsDeviceControlDisable = createSelector(
  [getEsitter, (esitter, deviceId) => deviceId],
  (esitter, deviceId) => {
    return (
      !!esitter.roomsArray.find(
        room =>
          room.deviceId === deviceId &&
          (room.deviceRebootState === DeviceRebootState.REBOOT || room.deviceInfo?.privacyMode || room.paused)
      ) || false
    );
  }
);

export const selectShortcutModalVisible = createSelector([getEsitter], esitter => esitter.shortcutModalVisible);

export const selectRoomsLatencyVisible = createSelector([getEsitter], esitter => esitter.roomsLatencyVisible);
