import { useCallback, useEffect } from 'react';
import { useEsitterWSMessages } from '../index';
import { useDispatch, useSelector } from 'react-redux';
import { getCallId } from '@provider-selectors/esitter';
import {
  getIsSettingUp,
  selectEnabled,
  selectIsSmartRailsPolygonEmpty,
  selectSensitivityLevel
} from '@provider-selectors/smartRails';
import { setGenerationType, setIsAutoPlacementExpired, setIsSettingUp } from '@provider-reducers/smartRailsSlice';
import { PolygonGeneratedType } from '@provider-types/enums';

type Props = {
  deviceId: string;
  cameraUuid: string;
  autoSetup?: boolean;
};

type useSmartRailsSetterReturn = {
  getAutoSmartRails: () => void;
  isSettingUp: boolean;
  setInitialSetupData: () => void;
};

export default function useSmartRailsSetter({
  deviceId,
  cameraUuid,
  autoSetup = false
}: Props): useSmartRailsSetterReturn {
  const callId = useSelector(getCallId);
  const { sendUpdateSmartRailsEnabledWSMessage } = useEsitterWSMessages(deviceId, callId);
  const isPolygonEmpty = useSelector(state => selectIsSmartRailsPolygonEmpty(state, cameraUuid));
  const boundaryEnabled = useSelector(state => selectEnabled(state, cameraUuid));
  const sensitivityLevel = useSelector(state => selectSensitivityLevel(state, cameraUuid));
  const isSettingUp = useSelector(state => getIsSettingUp(state, cameraUuid));

  const dispatch = useDispatch();

  const setInitialSetupData = useCallback(() => {
    dispatch(setGenerationType({ cameraUuid, generationType: PolygonGeneratedType.NONE }));
    dispatch(setIsSettingUp({ cameraUuid, isSettingUp: true }));
    dispatch(setIsAutoPlacementExpired({ cameraUuid, isExpired: false }));
  }, [cameraUuid, dispatch]);

  const getAutoSmartRails = useCallback(() => {
    if (!isSettingUp && cameraUuid) {
      setInitialSetupData();
      sendUpdateSmartRailsEnabledWSMessage(cameraUuid, boundaryEnabled, sensitivityLevel, []);
    }
  }, [
    boundaryEnabled,
    cameraUuid,
    isSettingUp,
    sendUpdateSmartRailsEnabledWSMessage,
    sensitivityLevel,
    setInitialSetupData
  ]);

  useEffect(() => {
    if (isPolygonEmpty && autoSetup) {
      getAutoSmartRails();
    }
  }, [autoSetup, getAutoSmartRails, isPolygonEmpty]);

  return {
    getAutoSmartRails,
    setInitialSetupData,
    isSettingUp
  };
}
