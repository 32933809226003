export enum EBrowserType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile'
}

export enum EButtonControlSize {
  EXTRALARGE = 'EXTRALARGE',
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL'
}

export enum EButtonControlColor {
  BLUE = 'BLUE',
  GRAY = 'GRAY',
  RED = 'RED',
  WHITE = 'WHITE'
}

export enum EToastStatus {
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  STARTINTERACTION = 'startinteraction',
  MUTEALL = 'muteall',
  MAKEHOST = 'makehost',
  OFFLINE = 'offline',
  LOADING = 'loading'
}

export enum DeviceStatus {
  ACTIVE = 'active',
  PENDING = 'pending'
}

export enum DeviceAvailability {
  ONLINE = 'online',
  USED = 'used',
  OFFLINE = 'offline'
}

export enum ESortType {
  ATOZ = 'a-z',
  ZTOA = 'z-a',
  TAG = 'tag',
  CUSTOM = 'custom'
}

export enum ETagColor {
  LEVEL1 = '#FF0000',
  LEVEL2 = '#FFA800',
  LEVEL3 = '#00857A',
  NONE = '#5B6B7B'
}

export enum ETagColorName {
  RED = 'RED',
  YEL = 'YEL',
  GRN = 'GRN',
  NONE = 'None'
}

export enum ETagColorFullName {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  NONE = 'NONE'
}

export enum ConnectionMode {
  OBSERVATION = 'observation_v',
  INTERACTIVE = 'interactive_av',
  LISTENING = 'observation_av'
}

export enum AlarmStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  LOADING = 'LOADING'
}

export enum EPrerecordedMessageGender {
  MALE = 'male',
  FEMALE = 'female'
}

export enum EPrerecordedMessageLang {
  ENGLISH = 'en',
  SPANISH = 'es',
  FRENCH = 'fr',
  RUSSIAN = 'ru'
}

export enum BlurModeStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

export enum EWebsocketEvent {
  ERROR = 'error',
  HEARTBEAT = 'heartbeat',
  DEVICE_CALL_STATE = 'deviceCallState',
  DEVICE_ALARM_STATE = 'deviceAlarmState',
  DEVICE_AUDIO_STATE = 'deviceAudioState',
  DEVICE_DEBUG_STATE = 'deviceDebugState',
  DEVICE_FILE_ACTION = 'deviceFileAction',
  DEVICE_VIDEO_FILTER = 'deviceVideoFilter',
  DEVICE_ZOOM_AT_POSITION = 'deviceZoomAtPosition',
  DEVICE_RECENTER_AND_ZOOM = 'deviceRecenterAndZoom',
  DEVICE_FECC_STATE = 'deviceFeccState',
  DEVICE_VIDEO_STATE = 'deviceVideoState',
  DEVICE_REBOOT_STATE = 'deviceRebootState',
  PARTICIPANT_CALL_STATE = 'participantCallState',
  DEVICE_VIRTUAL_BED_RAILS_STATE = 'deviceVirtualBedRailsState',
  DEVICE_BIO_MEASUREMENTS_STATE = 'deviceBioMeasurements',
  DEVICE_CAPABILITIES = 'deviceCapabilities',
  CAPTION_STATE = 'captionState',
  CAPTION_TRANSCRIPT = 'captionTranscript',
  BASE64_BINARY = 'base64Binary'
}

export enum EBinaryWebsocketAudioFormat {
  PCM = 'pcm',
  WEBM = 'webm'
}

export enum EWebsocketAudioEvent {
  AI_SCRIBE_CONVERSATION_AUDIO = 'aiScribeConversationAudio',
  CAPTION_AUDIO = 'captionAudio'
}

export enum LogEventLevel {
  INFO = 'info',
  DEBUG = 'debug',
  WARNING = 'warning',
  ERROR = 'error',
  CRITICAL = 'critical'
}

export enum ELogEvent {
  BLUR_PRIVACY_MODE = 'blurPrivacy',
  LISTEN_IN = 'listenIn',
  INTERVENTION = 'intervention',
  /** @TODO uncomment when NETWORK event is supported */
  // NETWORK = 'network'
  MESSAGES = 'messages',
  PRIVACY_MODE = 'privacy',
  ROOM_ALARM = 'roomAlarm',
  VR = 'vr'
}

export enum ELogEventPhase {
  STARTED = 'started',
  ENDED = 'ended',
  NONE = 'none'
}

export enum ECallType {
  VR = 'vr',
  ESITTER = 'esitter'
}

export enum DeviceRebootState {
  ALIVE = 'alive',
  REBOOT = 'reboot',
  FAIL = 'fail'
}

export enum KeyboardShortcutKey {
  FOCUS = 'f',
  SMART_RAILS = 's',
  MESSAGE = 'm',
  CAMERA_CONTROL = 'c',
  ALARM = 'a',
  LISTEN_MODE = 'l',
  BLUR_MODE = 'b',
  INTERVENTION = ' ', // spacebar
  SIDE_PANEL = '/',
  ADD_PATIENT = 'p',
  UNSELECT = 'escape'
}

export enum PolygonGeneratedType {
  DEFAULT = 'default',
  AUTO = 'auto',
  NONE = 'none'
}
