import { useCallback, useState, useEffect, useRef } from 'react';
import { useCommonWSMessages } from '../useCommonWsMessages/useCommonWsMessages';
import { EBinaryWebsocketAudioFormat, EWebsocketAudioEvent } from 'src/@types/enums';
import { isAiScribeFlagActive } from '@provider-utils/experimentalFlag';

export const useAiScribeRecording = (callId: string): any => {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [audioDestination, setAudioDestination] = useState<MediaStreamAudioDestinationNode | null>(null);
  // to disconnect tracks when they are removed by the application
  const sourceNodesMap = useRef<Map<string, MediaStreamAudioSourceNode>>(new Map());

  const recorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);

  // Audio tracks set by the application via setAudioTracksForAiScribe (directly exposed)
  const [audioTracksForAiScribe, setAudioTracksForAiScribe] = useState<MediaStreamTrack[]>([]);

  // combination of all tracks into one mono stream
  const [combinedStream, setCombinedStream] = useState<MediaStream | null>(null);
  const { sendAudioToTranscribe } = useCommonWSMessages(callId);

  // Initialize AudioContext and destination node
  useEffect(() => {
    // Only initialize the audio context if it doesn't already exist and we
    // should enable this hook
    if (isAiScribeFlagActive && !audioContext) {
      const newAudioContext = new AudioContext();
      setAudioContext(newAudioContext);
      const destination = newAudioContext?.createMediaStreamDestination();
      setAudioDestination(destination);

      // Hook up the 'combined stream' to the destination, which will
      // have all of the tracks combined together. This will be used by the
      // media recorder
      setCombinedStream(destination.stream);

      // Cleanup function to close the audio context when the component unmounts
      return () => {
        newAudioContext.close();
        setAudioContext(null);
        setAudioDestination(null);
        setCombinedStream(null);
      };
    }
  }, []);

  const sendAudioChunks = useCallback(
    async (chunks: Blob[]) => {
      const combinedSize = chunks.reduce((acc, chunk) => acc + chunk.size, 0);
      const readChunks = chunks.map(chunk => {
        return new Promise<ArrayBuffer>(resolve => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result as ArrayBuffer);
          };
          reader.readAsArrayBuffer(chunk);
        });
      });

      const arrayBuffers = await Promise.all(readChunks);
      const combinedChunk = new Uint8Array(combinedSize);
      let offset = 0;
      arrayBuffers.forEach(buffer => {
        combinedChunk.set(new Uint8Array(buffer), offset);
        offset += buffer.byteLength;
      });

      // Send the combined chunk to backend
      await sendAudioToTranscribe(
        combinedChunk.buffer,
        EBinaryWebsocketAudioFormat.WEBM,
        EWebsocketAudioEvent.AI_SCRIBE_CONVERSATION_AUDIO
      );

      // Debug - write the chunk to disk
      // Create an object URL for the blob
      /*
      const blob = new Blob([combinedChunk], { type: 'audio/webm; codecs=opus' });
      const url = URL.createObjectURL(blob);
      // Create a new anchor element
      const link = document.createElement('a');
      link.href = url;
      link.download = `recorded_audio_${Date.now()}.webm`; // Use a timestamp or another identifier for uniqueness
      document.body.appendChild(link); // Append the link to the document
      link.click(); // Programmatically click the link to trigger the download
      document.body.removeChild(link); // Remove the link from the document
      URL.revokeObjectURL(url); // Clean up the object URL
      */
    },
    [sendAudioToTranscribe]
  );

  // Function to handle data available event from MediaRecorder
  const onDataAvailable = useCallback(async (event: BlobEvent) => {
    if (event.data && event.data.size > 0) {
      chunksRef.current.push(event.data);
      console.info(`AI scribe: received chunk data from audio recorder`);
      // Check if we need to send data immediately or not
      const combinedSize = chunksRef.current.reduce((acc, chunk) => acc + chunk.size, 0);
      // Periodically send once we've collected > 6MB of data.
      // Note: *Must* be at least 5MB blocks to operate with S3 multipart uploads
      if (combinedSize >= 6 * 1024 * 1024) {
        // Don't await, start sending the existing chunks, then redefine the current ref so
        // we can continue writing to it.
        sendAudioChunks(chunksRef.current)
          .then(() => {
            console.info('AI Scribe: Chunks sent successfully from onDataAvailable');
          })
          .catch(error => {
            console.error('AI Scribe: error sending chunks from onDataAvailable', error);
          });
        // Reset the chunks ref after sending
        chunksRef.current = [];
      }
    }
  }, []);

  useEffect(() => {
    // Set up mediaRecorder for the first time
    if (combinedStream && !recorderRef.current) {
      const newRecorder = new MediaRecorder(combinedStream, {
        mimeType: 'audio/webm; codecs=opus',
        bitsPerSecond: 256000
      });

      // Connect the onDataAvailable function to the new recorder
      newRecorder.ondataavailable = onDataAvailable;
      // emit chunks every ~10 seconds.
      newRecorder.start(10000);
      recorderRef.current = newRecorder;

      // send remaining data and stop the recorder
      return () => {
        if (recorderRef.current && recorderRef.current.state !== 'inactive') {
          recorderRef.current.stop();
          recorderRef.current = null;
        }
      };
    }
  }, [combinedStream, onDataAvailable, sendAudioChunks]);

  const updateAiScribeRecordingTracks = useCallback(
    (newTracks: MediaStreamTrack[]) => {
      if (audioContext && audioDestination) {
        console.info(`AI Scribe: Updating list of tracks, total audio tracks: ${newTracks.length}`);
        // Remove tracks that the app is no longer reporting as available, disconnect
        // the attached source nodes
        sourceNodesMap.current.forEach((sourceNode, trackId) => {
          if (!newTracks.some(newTrack => newTrack.id === trackId)) {
            sourceNode.disconnect();
            sourceNodesMap.current.delete(trackId);
          }
        });

        // Add new tracks, track the source node for later removal
        newTracks.forEach(newTrack => {
          if (!sourceNodesMap.current.has(newTrack.id)) {
            const source = audioContext.createMediaStreamSource(new MediaStream([newTrack]));
            source.connect(audioDestination);
            sourceNodesMap.current.set(newTrack.id, source);
          }
        });
      } else {
        console.warn('AI SCRIBE: Audio context/destination/combined stream not available');
      }
    },
    [audioContext, audioDestination]
  );

  useEffect(() => {
    if (audioTracksForAiScribe.length > 0) {
      updateAiScribeRecordingTracks(audioTracksForAiScribe);
    }
  }, [audioTracksForAiScribe, updateAiScribeRecordingTracks]);

  // Should be called before the websocket connection is closed
  // Here, we await `sendAudioChunks` so that we know all data has been
  // transmitted successfully when this function returns, allowing 'ending the call'
  // to block on successful tranmsission.
  const endAiScribeRecording = useCallback(async () => {
    if (audioContext && audioDestination) {
      // Disconnect all sources/tracks to prevent streaming new data
      sourceNodesMap.current.forEach((sourceNode, trackId) => {
        sourceNode.disconnect();
        sourceNodesMap.current.delete(trackId);
      });

      if (recorderRef?.current) {
        // Prepare a promise that will be resolved when the last onDataAvailable event is handled
        let lastChunkSentPromiseResolve: ((val: unknown) => void) | undefined;
        const lastChunkSentPromise = new Promise(resolve => {
          lastChunkSentPromiseResolve = resolve;
        });

        // For the last chunk of data, don't wait for 6MB - send it immediately.
        recorderRef.current.ondataavailable = async event => {
          if (event.data && event.data.size > 0) {
            console.log(`AI Scribe: sending last data event, size is ${event.data.size}`);

            // There may be other chunks we haven't sent yet - include this one as the last
            // one in that block, and send the resulting 'chunk'.
            chunksRef.current.push(event.data);
            await sendAudioChunks(chunksRef.current);
            console.log('Sent audio data, resolving');
            if (lastChunkSentPromiseResolve) {
              lastChunkSentPromiseResolve('resolved');
              chunksRef.current = [];
            }
          }
        };

        console.log('Stopping recorder for the last time');
        recorderRef.current.stop();

        // Wait for the last chunk to be sent, which will resolve once the last ondataavailable
        // event completes
        await lastChunkSentPromise;
        recorderRef.current.ondataavailable = null;
        recorderRef.current = null;
      }
      /*
      if (chunksRef.current.length > 0) {
        console.info('AI Scribe: sending remaining data at end of call');
        await sendAudioChunks(chunksRef.current);
        chunksRef.current = [];

        // Empirically, 'a little time' is needed *even after* the websocket sends?
        //await new Promise(resolve => setTimeout(resolve, 2000));
      }
      */
    }
  }, [audioContext, audioDestination, sendAudioChunks]);

  return { endAiScribeRecording, setAudioTracksForAiScribe };
};
