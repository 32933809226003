import React, { useCallback } from 'react';
import { Menu } from '../Menu/Menu';
import { MenuData, MenuSecondaryContent, MenuSecondaryData } from '@provider-types/components';
import { useMediaDevices, IUseMediaDevicesMediaInfo } from '@provider-hooks';
import { useDispatch, useSelector } from 'react-redux';
// Images
import MicIcon from '@mui/icons-material/Mic';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VideocamIcon from '@mui/icons-material/Videocam';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { selectCmsVersion, selectProviderAppVersion } from '@provider-selectors/app';
import { selectAudioInputId, selectAudioOutputId, selectVideoInputId } from '@provider-selectors/settings';
import {
  setEsitterModalData,
  setEsitterModalVisible,
  setEsitterShortcutModalVisible
} from '@provider-reducers/esitterSlice';
import {
  setAudioOutputId,
  setSelectedAudioInputIdFromUser,
  setSelectedVideoInputIdFromUser
} from '@provider-reducers/settingsSlice';

export interface Props {
  refer: 'vrcall' | 'sitter';
  anchorEl: Element | null;
  extraSettingsItems?: {
    id: string;
    title: string;
    Icon: typeof MicIcon;
    onClick: () => void;
    disableSecondaryContent?: boolean;
  }[];
  hideSelectCamera?: boolean;
  positioning?: 'left' | 'right';
  onEnumerateDeviceNotSupported?: () => void;
  onClose: () => void;
}

export const MediaSelect: React.FC<Props> = ({
  refer,
  anchorEl,
  extraSettingsItems = [],
  hideSelectCamera = false,
  onEnumerateDeviceNotSupported,
  positioning,
  onClose
}) => {
  const { audioInputDevices, audioOutputDevices, videoInputDevices } = useMediaDevices({
    onEnumerateDeviceNotSupported
  });

  const audioInputId = useSelector(selectAudioInputId);
  const audioOutputId = useSelector(selectAudioOutputId);
  const videoInputId = useSelector(selectVideoInputId);
  const providerAppVersion = useSelector(selectProviderAppVersion);
  const cmsVersion = useSelector(selectCmsVersion);
  const dispatch = useDispatch();

  const onAboutClick = useCallback(() => {
    onClose();

    const currentYear = new Date().getFullYear();

    const payload = {
      title: 'About',
      description: `Version: ${providerAppVersion} / ${cmsVersion}<br /> Copyright © ${currentYear} LG Electronics`,
      primaryBtnName: 'Close'
    };

    if (refer === 'vrcall') {
      dispatch({
        type: 'SET_VR_MODAL_DATA',
        payload: payload
      });
      dispatch({
        type: 'SET_VR_MODAL_VISIBLE',
        payload: true
      });
    } else {
      dispatch(setEsitterModalData(payload));
      dispatch(setEsitterModalVisible(true));
    }
  }, [onClose, refer, dispatch, providerAppVersion, cmsVersion]);

  const onShortcutClick = useCallback(() => {
    onClose();

    dispatch(setEsitterShortcutModalVisible(true));
  }, [onClose, dispatch]);

  const infoMenuContent = [];

  if (refer === 'sitter') {
    infoMenuContent.push({
      id: 'info-shortcut',
      title: 'Keyboard shortcuts',
      Icon: KeyboardIcon,
      disableSecondaryContent: true,
      onClick: onShortcutClick
    });
  }

  infoMenuContent.push({
    id: 'info-about',
    title: 'About',
    Icon: InfoIcon,
    disableSecondaryContent: true,
    onClick: onAboutClick
  });

  const mediaSelectData: MenuData[] = [
    {
      label: 'SETTINGS',
      content: [
        {
          id: 'settings-select_microphone',
          title: 'Select microphone',
          Icon: MicIcon,
          onClick: () => {
            /** */
          }
        },
        {
          id: 'settings-select_speaker',
          title: 'Select speaker',
          Icon: VolumeUpIcon,
          onClick: () => {
            /** */
          }
        }
      ]
    },
    {
      label: 'INFO',
      content: infoMenuContent
    }
  ];

  !hideSelectCamera &&
    mediaSelectData[0].content.push({
      id: 'settings-select_camera',
      title: 'Select camera',
      Icon: VideocamIcon,
      onClick: () => {
        /** */
      }
    });

  if (extraSettingsItems && extraSettingsItems.length) {
    mediaSelectData[0].content.push(...extraSettingsItems);
  }

  const mediaSelectSecondaryData: MenuSecondaryData[] = [
    {
      selectId: 'settings-select_microphone',
      label: 'SELECT MICROPHONE',
      content: audioInputDevices.map(
        (device: IUseMediaDevicesMediaInfo): MenuSecondaryContent => ({
          title: device.label + (device.usable ? '' : ' (Unavailable)'),
          selected: device.deviceId === audioInputId,
          onClick: () => {
            if (device.usable) {
              dispatch(setSelectedAudioInputIdFromUser(device.deviceId));
            }
          }
        })
      )
    },
    {
      selectId: 'settings-select_speaker',
      label: 'SELECT SPEAKER',
      content: audioOutputDevices.map(
        (device: IUseMediaDevicesMediaInfo): MenuSecondaryContent => ({
          title: device.label + (device.usable ? '' : ' (Unavailable)'),
          selected: device.deviceId === audioOutputId,
          onClick: () => {
            if (device.usable) {
              dispatch(setAudioOutputId(device.deviceId));
            }
          }
        })
      )
    },
    {
      selectId: 'settings-select_camera',
      label: 'SELECT CAMERA',
      content: videoInputDevices.map(
        (device: IUseMediaDevicesMediaInfo): MenuSecondaryContent => ({
          title: device.label + (device.usable ? '' : ' (Unavailable)'),
          selected: device.deviceId === videoInputId,
          onClick: () => {
            if (device.usable) {
              dispatch(setSelectedVideoInputIdFromUser(device.deviceId));
            }
          }
        })
      )
    }
  ];

  return (
    <Menu
      data={mediaSelectData}
      secondaryData={mediaSelectSecondaryData}
      anchorEl={anchorEl}
      positioning={positioning}
      onClose={onClose}
    />
  );
};
