import React from 'react';
import { Spinner, TextButton } from '@provider-components';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Stack } from '@mui/material';
import { Reload } from '@provider-components/Icons';
import { useRebootDevice } from '@provider-hooks';
import { getCallId, getSelectedDeviceId } from '@provider-selectors/esitter';
import { ECallType } from '@provider-types/enums';
import { Trans } from 'react-i18next';

export default function RebootButton(): React.ReactElement {
  const { t } = useTranslation();
  const deviceId = useSelector(getSelectedDeviceId);
  const callId = useSelector(getCallId);
  const { rebootDevice, isDeviceRebooting } = useRebootDevice({ deviceId, callId, type: ECallType.ESITTER });

  if (isDeviceRebooting) {
    return (
      <Stack direction='row' alignItems={'center'} spacing={2} data-testid='rebootButtonProgress'>
        <Spinner size={24} />
        <Typography
          sx={{
            fontSize: '12px'
          }}
        >
          <Trans i18nKey={'global.rebooting'} />
        </Typography>
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        ml: '-12px!important'
      }}
    >
      <TextButton
        labelSx={{
          py: 0,
          px: 4
        }}
        color={'primary'}
        fullWidth
        title={t('esitter.components.SidePanel.RebootButton')}
        icon={Reload}
        onClick={rebootDevice}
      />
    </Box>
  );
}
