import React, { useCallback } from 'react';
import { ButtonControl } from '@provider-components';
import { EButtonControlColor, EButtonControlSize } from '@provider-types/enums';
import LogoutSvg from '@provider-assets/images/logout.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { EmbedRouteNames } from '@provider-features/embed/router';
import { setEsitterModalData, setEsitterModalVisible, setRedirectDisabled } from '@provider-reducers/esitterSlice';

export const LogoutButton = React.memo((): React.ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onEndSession = useCallback(() => {
    //Set Confirmation pop up message

    dispatch(setRedirectDisabled(false));
    dispatch(
      setEsitterModalData({
        title: 'End Session',
        description: 'Ending Session will end monitoring for all patients.',
        secondaryBtnName: 'Cancel',
        primaryBtnName: 'End Session',
        onPrimaryBtnClick: () => {
          //Set end Screen message and Icon
          dispatch({
            type: 'SET_END_CALL_ICON',
            payload: LogoutSvg
          });
          dispatch({
            type: 'SET_END_CALL_MESSAGE',
            payload: 'Session Ended.'
          });

          navigate(EmbedRouteNames.CallEnded);
          // Loading call ended page will trigger useEffect cleanup in eSitter to Stop Call
        },
        onSecondaryBtnClick: () => {
          dispatch(setRedirectDisabled(true));
        }
      })
    );
    dispatch(setEsitterModalVisible(true));
  }, [dispatch, navigate]);
  return (
    <ButtonControl
      size={EButtonControlSize.MEDIUM}
      backgroundColor='#313A42'
      color={EButtonControlColor.GRAY}
      onClick={onEndSession}
      data-testid='logOutBtn'
    >
      <img alt='logout' src={LogoutSvg} />
    </ButtonControl>
  );
});
