import { SvgIcon, Theme } from '@mui/material';
import React, { useMemo, forwardRef } from 'react';
import MUIButton from '@mui/material/Button';
import { makeThemedStyles } from '@provider-hooks';
import { ButtonProps } from '@mui/material/Button/Button';
import { mergeSx } from '@provider-utils/styles';
import { SxProps } from '@mui/system/styleFunctionSx';

export interface TextButtonProps extends ButtonProps {
  title: string;
  icon: typeof SvgIcon;
  iconSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
}
export const TextButton = React.memo(
  forwardRef<HTMLButtonElement, TextButtonProps>((props, ref): JSX.Element => {
    const { icon: IconComponent, title, iconSx, labelSx, ...otherProps } = props;
    const { styles } = useStyles();

    const iconComponent = useMemo(() => <IconComponent sx={iconSx} />, [IconComponent, iconSx]);

    return (
      <MUIButton ref={ref} {...otherProps} variant='text' startIcon={iconComponent} sx={mergeSx(styles.label, labelSx)}>
        {title}
      </MUIButton>
    );
  })
);

const useStyles = makeThemedStyles()(() => ({
  label: { justifyContent: 'start' }
}));
