import { v5 as uuidv5 } from 'uuid';
import { EPrerecordedMessageGender } from '@provider-types/enums';
import { PrerecordedMessage } from '@provider-types/reducer';

type MessageList = {
  messages: Message[];
};

type Message = {
  text: string;
  audio: Record<string, Record<EPrerecordedMessageGender, string>>;
};

const NAMESPACE = '1b3601a6-7466-499c-adbc-043ed44690f0';

// dev URL
let PRERECORDED_MESSAGES_TARGET = 'https://precanned.view.dhcth.link';
if (process.env.BUILD_PRODUCTION_CODE === 'true') {
  PRERECORDED_MESSAGES_TARGET = 'https://precanned-messages.telehealth.lgdh-procentric.com';
}

const INACTIVE_LANGUAGES: string[] = [];

export async function getPrerecordedMessages(): Promise<PrerecordedMessage[]> {
  const list: PrerecordedMessage[] = [];

  try {
    const res = await fetch(`${PRERECORDED_MESSAGES_TARGET}/manifest.json`, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    });

    const body = (await res.json()) as MessageList;

    body.messages.forEach(({ text, audio }) => {
      for (const lang in audio) {
        for (const gender in audio[lang]) {
          const genderIndex = gender as EPrerecordedMessageGender;

          if (INACTIVE_LANGUAGES.includes(lang)) continue;

          list.push({
            /** generate the same id based on other fields - useful during rerender not to lose playing context */
            id: uuidv5(`${text}_${lang}_${gender}`, NAMESPACE),
            text,
            gender: genderIndex,
            lang,
            path: `${PRERECORDED_MESSAGES_TARGET}/${audio[lang][genderIndex]}`
          });
        }
      }
    });
  } catch (err) {
    return list;
  }

  return list;
}
