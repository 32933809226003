import React, { useMemo, forwardRef } from 'react';
import { default as MuiIconButton } from '@mui/material/IconButton';
import { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton/IconButton';
import { makeThemedStyles } from '@provider-hooks';
import { SvgIcon, SxProps, Theme } from '@mui/material';
import { mergeSx } from '@provider-utils/styles';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

export interface IconButtonProps extends MuiIconButtonProps {
  isToggled?: boolean;
  icon: typeof SvgIcon;
  iconSx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
  tipTitle?: string;
  tipProps?: Omit<TooltipProps, 'children' | 'title'>;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref): React.ReactElement => {
  const { icon: Icon, iconSx, buttonSx, isToggled = false, tipTitle = '', tipProps, ...otherProps } = props;
  const { styles } = useStyles({ isToggled });
  const mergedIconSx = useMemo(() => mergeSx(styles.icon, iconSx), [iconSx, styles.icon]);
  const mergedButtonSx = useMemo(() => mergeSx(styles.button, buttonSx), [buttonSx, styles.button]);

  return (
    <Tooltip title={tipTitle} {...tipProps}>
      <MuiIconButton sx={mergedButtonSx} {...otherProps} ref={ref} role={'button'} data-toggled={isToggled}>
        <Icon sx={mergedIconSx} />
      </MuiIconButton>
    </Tooltip>
  );
});

const useStyles = makeThemedStyles<{ isToggled: boolean }>()((_, { isToggled }) => ({
  button: {
    width: '32px',
    height: '32px',
    '&.MuiIconButton-colorPrimary': {
      backgroundColor: isToggled ? '#1774cc' : '#313a42',
      '&:hover': {
        backgroundColor: '#1774cc'
      },
      '&:active': {
        backgroundColor: '#46aff2'
      }
    },
    '&.MuiIconButton-colorError': {
      backgroundColor: '#d93b3d',

      '&:hover': {
        backgroundColor: '#ff787a'
      },
      '&:active': {
        backgroundColor: '#b60002'
      }
    },
    '&:disabled': {
      backgroundColor: '#5b6b7b'
    }
  },
  icon: {
    color: '#ffffff',
    width: '20px',
    height: '20px'
  }
}));
