import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router';
import { addParticipant } from '@provider-api/embed';
import { VRCallRouteNames } from '@provider-features/vrcall/router';
import { useDispatch } from 'react-redux';
import { EBrowserType } from '@provider-types/enums';
import { EmbedRouteNames } from '../../router';
import { useWebsocketHelper } from 'src/wsHelper';
import { isBackendError } from '@provider-errors/BackendError';
import { setCmsVersion } from '@provider-reducers/appSlice';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { DeviceRoom } from '@provider-types/provider';

const DirectJoin: React.FC = () => {
  const { search } = useLocation();

  const [loggedIn, setLoggedIn] = useState(false);
  const [initialDeviceRooms, setInitialDeviceRooms] = useState<DeviceRoom[]>([]);
  const [myUserUuid, setMyUserUuid] = useState('');
  const { initiateWsConnection, wsReady } = useWebsocketHelper();
  const { callId, otpToken } = useParams() as { callId: string; otpToken: string };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!callId || !loggedIn || !wsReady) {
      return;
    }

    navigate(VRCallRouteNames.VRCall, {
      state: {
        call: { callUuid: callId, deviceRooms: initialDeviceRooms },
        userUuid: myUserUuid,
        isParticipant: true
      }
    });
  }, [dispatch, callId, initialDeviceRooms, myUserUuid, navigate, loggedIn, wsReady]);

  useEffect(() => {
    if (!callId || !otpToken || loggedIn) return;

    async function handleDirectJoin() {
      const params = new URLSearchParams(search);

      const name = params.get('displayName') as string;
      const browserType = params.get('browser') as EBrowserType;

      const response = await addParticipant(callId, {
        displayName: name.trim(),
        otpToken: otpToken,
        browserType: browserType
      });

      if (!isBackendError(response)) {
        const { body: participant, headers } = response;
        dispatch({
          type: 'SET_ALL_PARTICIPANT_STATUS',
          payload: participant.participantStatus
        });

        dispatch(setCmsVersion(headers.get('x-cms-version') as string));

        setInitialDeviceRooms(participant.deviceRooms);
        setMyUserUuid(participant.userUuid);

        setLoggedIn(true);
        initiateWsConnection();
      } else if (isBackendError(response) && response.status === 400) {
        const body = response.payload;

        if (body[0]?.keyword === 'notExist' && body[0]?.dataPath === 'callId') {
          console.log('Call has already ended.');
          dispatch({
            type: 'SET_END_CALL_ICON',
            payload: <CallEndIcon sx={{ color: '#BEC3CC' }} />
          });
          dispatch({
            type: 'SET_END_CALL_MESSAGE',
            payload: 'This meeting you are trying to join <br /> has been ended by the host.'
          });
          navigate(EmbedRouteNames.CallEnded);
        } else {
          console.log('Error on joining.');
          dispatch({
            type: 'SET_END_CALL_ICON',
            payload: <CallEndIcon sx={{ color: '#BEC3CC' }} />
          });
          dispatch({
            type: 'SET_END_CALL_MESSAGE',
            payload: 'There was an error with your invitation link.'
          });
          navigate(EmbedRouteNames.CallEnded);
        }
      }
    }

    handleDirectJoin();
  }, [callId, dispatch, initiateWsConnection, loggedIn, navigate, otpToken, search]);

  return <></>;
};

export default DirectJoin;
