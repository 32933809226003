import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const Reload = createSvgIcon(
  <>
    <path
      // eslint-disable-next-line max-len
      d='m 10.495 22.7046 c -2.4503 -0.3038 -4.4803 -1.3718 -6.0901 -3.2045 c -1.6099 -1.8325 -2.4149 -3.9841 -2.4149 -6.4546 c 0 -1.3365 0.2633 -2.6174 0.7897 -3.8425 c 0.5265 -1.2251 1.2757 -2.2933 2.2477 -3.2045 l 1.7314 1.7314 c -0.7695 0.6885 -1.3516 1.4884 -1.7465 2.3996 c -0.3949 0.9113 -0.5924 1.8833 -0.5924 2.916 c 0 1.782 0.567 3.3564 1.701 4.7234 c 1.134 1.3668 2.592 2.2021 4.374 2.5058 v 2.43 z m 2.43 0 v -2.43 c 1.7617 -0.324 3.2147 -1.1643 4.3589 -2.521 c 1.1441 -1.3568 1.7161 -2.9261 1.7161 -4.7081 c 0 -2.025 -0.7087 -3.7463 -2.1263 -5.1638 c -1.4175 -1.4175 -3.1388 -2.1263 -5.1638 -2.1263 h -0.0911 l 1.3365 1.3365 l -1.701 1.701 l -4.2525 -4.2525 l 4.2525 -4.2525 l 1.701 1.701 l -1.3365 1.3365 h 0.0911 c 2.7135 0 5.0119 0.9416 6.8952 2.8249 c 1.8833 1.8833 2.8248 4.1816 2.8248 6.8951 c 0 2.4503 -0.805 4.5916 -2.4148 6.4244 c -1.61 1.8325 -3.64 2.9108 -6.0902 3.2348 z'
    />
  </>,
  'Reload'
);
