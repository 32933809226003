import { Box, FormControlLabel, Stack, Typography, keyframes } from '@mui/material';
import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIsSettingUp,
  getSelectedCameraSmartRailsPolygon,
  isBoundariesSetUpForSelectedCamera,
  selectEnabled,
  selectIsSaved,
  selectSensitivityLevel
} from '@provider-selectors/smartRails';
import { RootState } from '@provider-reducers/root';
import { useEsitterWSMessages, useSmartRailsSetter } from '../../../../hooks';
import {
  getCallId,
  getSelectedDeviceId,
  selectDeviceSelectedCameraUuid,
  selectIsDeviceControlDisable
} from '@provider-selectors/esitter';
import {
  setBoundaryFirstTime,
  setBoundaryToDefault,
  setSaveDisabled,
  setSavingWithTimeout,
  setIsSettingUp
} from '@provider-reducers/smartRailsSlice';
import { transformPointsToPolygon } from '../../../../functions';
import {
  setShowSmartRailsCameraChangedWarningOff,
  setShowSmartRailsPTZChangedWarningOff
} from '@provider-reducers/uiState';
import { makeThemedStyles } from '@provider-hooks';
import { Trans } from 'react-i18next';

const rotationAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

interface SmartrailsSwitchProps {
  disabled: boolean;
}

export const SmartrailsSwitch = (props: SmartrailsSwitchProps): React.ReactElement => {
  const { disabled } = props;
  const { styles } = useStyles();

  const dispatch = useDispatch();

  const callId = useSelector(getCallId);
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const selectedCameraUuid = useSelector(state => selectDeviceSelectedCameraUuid(state, selectedDeviceId));
  const isDeviceControlDisable = useSelector(state => selectIsDeviceControlDisable(state, selectedDeviceId));

  const boundaryEnabled = useSelector((state: RootState) => selectEnabled(state, selectedCameraUuid)) || false;
  const isBoundariesSetUp = useSelector(isBoundariesSetUpForSelectedCamera);
  const sensitivityLevel = useSelector((state: RootState) => selectSensitivityLevel(state, selectedCameraUuid));
  const points = useSelector(getSelectedCameraSmartRailsPolygon);
  const loaded = useSelector(selectIsSaved);
  const isSmartRailsSettingUp = useSelector(state => getIsSettingUp(state, selectedCameraUuid));
  const { setInitialSetupData } = useSmartRailsSetter({ cameraUuid: selectedCameraUuid, deviceId: selectedDeviceId });

  const [switchEnabled, setSwitchEnabled] = useState(boundaryEnabled);
  const { sendUpdateSmartRailsEnabledWSMessage } = useEsitterWSMessages(selectedDeviceId, callId);

  useEffect(() => {
    setSwitchEnabled(boundaryEnabled);
  }, [boundaryEnabled]);

  useEffect(() => {
    if (!isBoundariesSetUp) {
      dispatch(setBoundaryToDefault({ cameraUuid: selectedCameraUuid, deviceId: selectedDeviceId }));
    }
  }, [dispatch, isBoundariesSetUp, selectedCameraUuid, selectedDeviceId]);

  const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = event.target.checked;
    setSwitchEnabled(enabled);

    const polygon = transformPointsToPolygon(points);
    sendUpdateSmartRailsEnabledWSMessage(selectedCameraUuid, enabled, sensitivityLevel, polygon);
    dispatch(setSavingWithTimeout(selectedCameraUuid));
    dispatch(setSaveDisabled({ cameraUuid: selectedCameraUuid, disabled: true }));
    dispatch(setShowSmartRailsCameraChangedWarningOff());
    dispatch(setShowSmartRailsPTZChangedWarningOff());

    if (!polygon.length && enabled) {
      setInitialSetupData();
    } else if (!enabled) {
      dispatch(setIsSettingUp({ cameraUuid: selectedCameraUuid, isSettingUp: false }));
    }
  };

  useEffect(() => {
    if (loaded) {
      dispatch(setBoundaryFirstTime({ cameraUuid: selectedCameraUuid }));
    }
  }, [dispatch, loaded, selectedCameraUuid]);

  const label = (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <Box component={'span'} sx={{ textTransform: 'uppercase' }}>
        <Trans i18nKey={`global.switch.${switchEnabled ? 'on' : 'off'}`} />
      </Box>
      {isSmartRailsSettingUp && (
        <Typography variant='caption' color={'text.secondary'}>
          <Trans i18nKey={`global.generating`} />
        </Typography>
      )}
    </Stack>
  );

  return (
    <FormControlLabel
      data-testid='alertSwitch'
      control={
        <Switch
          disabled={!!(disabled || isDeviceControlDisable)}
          checked={switchEnabled}
          onChange={handleEnabledChange}
          sx={isSmartRailsSettingUp ? styles.swatch : undefined}
        />
      }
      label={label}
      color={'text.secondary'}
    />
  );
};

const useStyles = makeThemedStyles()(() => ({
  swatch: {
    '& .MuiSwitch-switchBase': {
      '& .MuiSwitch-thumb': {
        position: 'relative',
        overflow: 'hidden',
        '&:before': {
          animation: `${rotationAnimation} 1.3s linear infinite`,
          content: "''",
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75 6C1.75 3.65279 3.65279 1.75 6 1.75L6 0.25C2.82436 0.25 0.25 2.82436 0.25 6L1.75 6ZM6 10.25C3.65279 10.25 1.75 8.34721 1.75 6L0.25 6C0.25 9.17564 2.82436 11.75 6 11.75C9.17564 11.75 11.75 9.17564 11.75 6L10.25 6C10.25 8.34721 8.34721 10.25 6 10.25Z' fill='white'/%3E%3C/svg%3E")`
        }
      }
    }
  }
}));
