import React, { useCallback, useEffect, useMemo } from 'react';
import { List } from '@mui/material';
import MessageListItem from './MessageListItem';

import { useDispatch, useSelector } from 'react-redux';
import { fetchEsitterMessages } from '@provider-reducers/esitterSlice';
import {
  getCallId,
  selectMessageList,
  selectRoomMessageGender,
  selectRoomMessageLang,
  selectRoomMessagePlaying
} from '@provider-selectors/esitter';
import {
  WsFileActionsParams,
  useEsitterWSMessages
} from '@provider-features/esitter/hooks/useEsitterWSMessages/useEsitterWSMessages';
import { addEsitterLogEntry } from '@provider-reducers/logSlice';
import { ELogEvent, ELogEventPhase } from '@provider-types/enums';

type Props = {
  deviceId: string;
  disabled?: boolean;
};

export const MessageSelector = ({ deviceId, disabled = false }: Props): React.ReactElement => {
  const callId = useSelector(getCallId);
  const lang = useSelector(state => selectRoomMessageLang(state, deviceId));
  const gender = useSelector(state => selectRoomMessageGender(state, deviceId));
  const { messageId: messagePlayingId, progress: messagePlayingProgress = 80 } =
    useSelector(state => selectRoomMessagePlaying(state, deviceId)) || {};
  const messagesList = useSelector(selectMessageList);

  const messages = useMemo(
    () => messagesList.filter(message => message.gender === gender && message.lang === lang),
    [messagesList, gender, lang]
  );
  const dispatch = useDispatch();
  const { sendFileActionWSMessage } = useEsitterWSMessages(deviceId, callId);

  useEffect(() => {
    dispatch(fetchEsitterMessages());
  }, [dispatch]);

  const onMessageItemClick = useCallback(
    (params: WsFileActionsParams) => {
      const { data = {}, ...restParams } = params;
      sendFileActionWSMessage(restParams);

      /** @TODO replace by log event emitter when implemented */
      dispatch(
        addEsitterLogEntry({
          type: ELogEvent.MESSAGES,
          phase: ELogEventPhase.NONE,
          deviceId,
          description: 'text' in data && 'lang' in data ? `"${data?.text}", ${data?.lang}` : undefined
        })
      );
    },
    [deviceId, dispatch, sendFileActionWSMessage]
  );

  const listItems = messages.map(({ id, text, path, lang }) => {
    const isPlaying = messagePlayingId === id;

    return (
      <MessageListItem
        progress={isPlaying ? messagePlayingProgress : 0}
        key={id}
        playing={isPlaying}
        text={text}
        id={id}
        lang={lang}
        onClick={onMessageItemClick}
        fileUri={path}
        disabled={disabled}
      />
    );
  });

  return (
    <List
      sx={{
        padding: '0 4px'
      }}
    >
      {listItems}
    </List>
  );
};
