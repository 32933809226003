import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { setSidePanelContent } from '@provider-reducers/esitterSlice';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { CircularProgress, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { SidebarBackNavigation } from '../SidebarBackNavigation/SidebarBackNavigation';
import { SidePanelContent } from '../../SidePanel';
import { selectSelectedDeviceSelectedCameraUuid, getSelectedDeviceId } from '@provider-selectors/esitter';
import {
  getBoundaryByCameraUuid,
  getSelectedCameraSmartRailsFirstTime,
  isBoundariesSetUpForSelectedCamera,
  selectEnabled,
  selectIsSaved,
  selectIsSaving,
  selectSaveDisabled,
  selectSensitivityLevel
} from '@provider-selectors/smartRails';
import { RootState } from '@provider-reducers/root';
import {
  resetSaveBoundaries,
  setBoundaryPoints,
  setIsSettingUp,
  setBoundaryToDefault,
  setEditMode,
  setIsSaved,
  setSaveDisabled,
  setSensitivityLevel as setSensitivityLevelAction
} from '@provider-reducers/smartRailsSlice';
import { SaveSmartRailsButton } from '../../SaveSmartRailsButton/SaveSmartRailsButton';
import FormControl from '@mui/material/FormControl';
import { ScrollableContainer } from '../ScrollableContainer/ScrollableContainer';
import Box from '@mui/material/Box';
import useSmartRailsSetter from '@provider-features/esitter/hooks/useSmartRailsSetter/useSmartRailsSetter';
import MUIButton from '@mui/material/Button';
import { Trans } from 'react-i18next';

export enum SmartRailsSensitivityLevel {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low'
}
export const SmartRailsContent = (): React.ReactElement => {
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const isBoundariesSetUp = useSelector(isBoundariesSetUpForSelectedCamera);
  const selectedCameraUuid = useSelector(selectSelectedDeviceSelectedCameraUuid);
  const boundaryEnabled = useSelector((state: RootState) => selectEnabled(state, selectedCameraUuid));
  const smartRailsSaveDisabled = useSelector((state: RootState) => selectSaveDisabled(state, selectedCameraUuid));
  const boundarySensitivityLevel = useSelector((state: RootState) => selectSensitivityLevel(state, selectedCameraUuid));
  const { points: boundaryPoints } = useSelector(state => getBoundaryByCameraUuid(state, selectedCameraUuid));

  const isSmartRailsSettingSaving = useSelector(selectIsSaving);
  const isSmartRailsSettingSaved = useSelector(selectIsSaved);

  const boundaryFirstTime = useSelector(getSelectedCameraSmartRailsFirstTime);

  const [sensitivityLevel, setSensitivityLevel] = useState<SmartRailsSensitivityLevel>(boundarySensitivityLevel);
  const initialBoundaryPoints = useRef(boundaryPoints);
  const enabled = useMemo(() => boundaryEnabled, [boundaryEnabled]);
  const isComponentUnmount = useRef(false);

  const dispatch = useDispatch();

  const { getAutoSmartRails, isSettingUp: isSmartRailsSettingUp } = useSmartRailsSetter({
    deviceId: selectedDeviceId,
    cameraUuid: selectedCameraUuid,
    autoSetup: true
  });

  const resetSmartRailsSettings = useCallback(() => {
    if (!isSmartRailsSettingSaved && !isSmartRailsSettingSaving) {
      dispatch(setBoundaryPoints({ cameraUuid: selectedCameraUuid, points: initialBoundaryPoints.current }));
      dispatch(setIsSettingUp({ cameraUuid: selectedCameraUuid, isSettingUp: false }));
      dispatch(setSaveDisabled({ cameraUuid: selectedCameraUuid, disabled: true }));
      if (!boundaryFirstTime) {
        dispatch(setIsSaved({ cameraUuid: selectedCameraUuid, cancelTimer: true }));
      } else {
        dispatch(resetSaveBoundaries({ cameraUuid: selectedCameraUuid }));
      }
    }
  }, [boundaryFirstTime, dispatch, isSmartRailsSettingSaved, isSmartRailsSettingSaving, selectedCameraUuid]);

  useEffect(() => {
    return () => {
      isComponentUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    dispatch(setEditMode({ cameraUuid: selectedCameraUuid, editMode: true }));
    return () => {
      if (isComponentUnmount.current) {
        resetSmartRailsSettings();
        dispatch(setEditMode({ cameraUuid: selectedCameraUuid, editMode: false }));
      }
    };
  }, [dispatch, resetSmartRailsSettings, selectedCameraUuid, selectedDeviceId]);

  useEffect(() => {
    if (!isBoundariesSetUp) {
      dispatch(setBoundaryToDefault({ cameraUuid: selectedCameraUuid, deviceId: selectedDeviceId }));
    }
  }, [dispatch, isBoundariesSetUp, selectedCameraUuid, selectedDeviceId]);

  const onBackClick = () => {
    dispatch(setSidePanelContent(SidePanelContent.PATIENT_DETAILS));
  };

  const handleSensitivityLevelChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setSensitivityLevel((value as SmartRailsSensitivityLevel) || SmartRailsSensitivityLevel.MEDIUM);

    if (!enabled) {
      dispatch(
        setSensitivityLevelAction({
          cameraUuid: selectedCameraUuid,
          sensitivityLevel: (value as SmartRailsSensitivityLevel) || SmartRailsSensitivityLevel.MEDIUM
        })
      );
    }

    if (smartRailsSaveDisabled) {
      dispatch(setSaveDisabled({ cameraUuid: selectedCameraUuid, disabled: false }));
    }
  };

  return (
    <>
      <Box sx={{ pt: 1, px: 2 }}>
        <SidebarBackNavigation title={'Patient Details'} onClick={onBackClick} />
      </Box>
      <ScrollableContainer
        mainContainer={
          <Box sx={{ px: 4, pb: 2 }}>
            <Divider sx={{ mt: 2.5, mb: 4 }} />
            <Typography sx={{ mb: 1 }} variant='h4'>
              Alert Sensitivity
            </Typography>
            <FormControl>
              <RadioGroup
                value={sensitivityLevel}
                name='sensitivityLevel'
                onChange={handleSensitivityLevelChange}
                color={'text.secondary'}
              >
                <FormControlLabel
                  value={SmartRailsSensitivityLevel.HIGH}
                  control={<Radio />}
                  label='High'
                  componentsProps={{ typography: { fontWeight: 600 } }}
                />
                <Typography variant='body1' color={'text.secondary'}>
                  Any part of the patient’s body crossing the Smart Rails border will trigger an alert.
                </Typography>
                <FormControlLabel
                  componentsProps={{ typography: { fontWeight: 600 } }}
                  value={SmartRailsSensitivityLevel.MEDIUM}
                  control={<Radio />}
                  label='Medium'
                />
                <Typography variant='body1' color={'text.secondary'}>
                  If either an arm or a leg crosses the Smart Rails border, an alert will be triggered.
                </Typography>
                <FormControlLabel
                  componentsProps={{ typography: { fontWeight: 600 } }}
                  value={SmartRailsSensitivityLevel.LOW}
                  control={<Radio />}
                  label='Low'
                />
                <Typography variant='body1' color={'text.secondary'}>
                  If either a head or a torso crosses the Smart Rails border, an alert will be triggered.
                </Typography>
              </RadioGroup>
            </FormControl>
            <Divider sx={{ my: 4 }} />
            <Box sx={{ mt: 4 }}>
              <Typography variant='h4'>
                <Trans i18nKey={'smartRails.generateLabel'} />
              </Typography>
              <Stack spacing={1} sx={{ mt: 4 }} alignItems={'flex-start'}>
                <MUIButton
                  data-testid='autoPlacementGenerate'
                  variant='outlined'
                  color='primary'
                  onClick={getAutoSmartRails}
                  sx={{
                    minWidth: '100px',
                    '&.Mui-disabled': { border: ({ palette }) => `2px solid ${palette.primary.light}` }
                  }}
                  disabled={isSmartRailsSettingUp}
                >
                  {isSmartRailsSettingUp ? <CircularProgress size={16} /> : <Trans i18nKey={'global.generate'} />}
                </MUIButton>
              </Stack>
            </Box>
            <Divider sx={{ my: 4 }} />

            <Typography variant='h4'>Instructions</Typography>
            <Stack spacing={1} sx={{ mt: 4 }}>
              <Typography variant={'body1'} color={'text.secondary'}>
                Click and drag the points on Smart Rails to adjust the shape of the border.
              </Typography>
            </Stack>
          </Box>
        }
        bottomContainer={
          <Box sx={{ p: 2 }}>
            <SaveSmartRailsButton
              enable={enabled}
              sensitivityLevel={sensitivityLevel}
              disabled={smartRailsSaveDisabled}
            />
          </Box>
        }
      />
    </>
  );
};
