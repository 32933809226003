import React, { useRef, useEffect, useState, useMemo } from 'react';
import * as d3 from 'd3';

import { getRangeMappedColor } from '@provider-features/esitter/functions';

import { ColorRange } from '@provider-types/esitter.types';

type Props = {
  level: number;
  width: number;
  duration?: number;
  ranges: ColorRange;
  min?: number;
  max?: number;
  barHeight?: number;
};

const DEFAULT_INACTIVE_COLOR = '#232A30';
export const BAR_WIDTH_PADDING = 1;
export const BAR_WIDTH = 2;
export const DEFAULT_MIN = 30;
export const DEFAULT_MAX = 90;

export const Graph = ({
  level,
  ranges,
  duration = 200,
  min = DEFAULT_MIN,
  max = DEFAULT_MAX,
  barHeight = 8,
  width
}: Props): React.ReactElement | null => {
  const [interpolatedValue, setInterpolatedValue] = useState(min);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const prevValue = useRef(min);
  const prevColor = useRef('gray');
  const interpolatedColor = useRef('gray');
  const canvasContext = useRef<CanvasRenderingContext2D>();

  const bars = useMemo(() => {
    const barsNum = Math.floor(width / (BAR_WIDTH + BAR_WIDTH_PADDING));
    const tick = (max - min) / barsNum;

    return [...Array(barsNum)].map((_, idx) => idx * tick + min);
  }, [width, max, min]);

  const scale = useMemo(() => {
    return d3.scaleLinear().range([0, width]).domain([min, max]);
  }, [width, min, max]);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    const context = canvas.getContext('2d');

    if (!context) return;

    canvasContext.current = context;
  }, []);

  useEffect(() => {
    const normalizedLevel = Math.max(Math.min(level, max), min);

    if (normalizedLevel === prevValue.current && level > min) {
      return;
    }

    const levelColor = getRangeMappedColor(ranges, normalizedLevel);

    const interpolate = d3.interpolateNumber(prevValue.current, normalizedLevel);
    const interpolateColor = d3.interpolateRgb(prevColor.current, levelColor);

    const timer = d3.timer(elapsed => {
      const newVal = Math.ceil(interpolate(d3.easeQuadInOut(elapsed / duration)));
      setInterpolatedValue(newVal);

      interpolatedColor.current = interpolateColor(d3.easeQuadInOut(elapsed / duration));

      if (elapsed > duration) {
        setInterpolatedValue(normalizedLevel);
        interpolatedColor.current = levelColor;

        timer.stop();
      }

      return () => {
        setInterpolatedValue(normalizedLevel);
        interpolatedColor.current = levelColor;

        timer.stop();
      };
    });

    prevValue.current = normalizedLevel;
    prevColor.current = levelColor;

    return () => {
      timer.stop();
    };
  }, [level, duration, ranges, max, min]);

  useEffect(() => {
    const drawCanvas = () => {
      if (!canvasContext.current) return;

      const context = canvasContext.current;

      context.clearRect(0, 0, width, barHeight);

      bars.forEach(function (bar) {
        context.beginPath();
        context.roundRect(scale(bar), 0, BAR_WIDTH, barHeight, [20]);
        context.fillStyle = bar > interpolatedValue ? DEFAULT_INACTIVE_COLOR : interpolatedColor.current;
        context.fill();
        context.closePath();
      });
    };

    drawCanvas();
  }, [interpolatedValue, min, max, width, bars, scale, barHeight]);

  return (
    <canvas
      data-testid={'AudioVisualizer_Graph'}
      role='img'
      aria-label={`${interpolatedValue} db`}
      ref={canvasRef}
      width={width}
      height={barHeight}
    />
  );
};
