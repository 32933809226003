import { Stack, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconWithText } from '../IconWithText/IconWithText';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { TextButton } from '@provider-components';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import MicIcon from '@mui/icons-material/Mic';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import CallIcon from '@mui/icons-material/Call';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsCameraControlActive,
  setIsAudioControlActive,
  handleChangeMode,
  setListenModeDevices,
  setListenModeConnectionStatus,
  setInterventionConnectionStatus,
  setIsMicActive
} from '@provider-reducers/esitterSlice';
import {
  getSelectedDevice,
  getSelectedDeviceId,
  selectInteractiveDevice,
  selectIsAudioControlActive,
  selectIsCameraControlActive,
  selectIsDeviceInInteractiveMode,
  selectSelectedDevicePaused,
  selectIsInterventionConnecting,
  selectIsInterventionDisconnecting,
  selectInterventionConnectionDeviceId,
  selectSelectedDeviceCanStartIntervention,
  selectIsDeviceInListenMode,
  selectListenModeDevices,
  selectListenModeDevicesStorageWhileIntervention,
  selectIsListenModeConnecting,
  selectIsListenModeDisconnecting,
  selectListenModeConnectionDeviceId,
  getCallId,
  selectIsDeviceInPrivacyMode,
  selectIsMicActive,
  selectIsDeviceControlDisable
} from '@provider-selectors/esitter';
import { AdjustCameraButton } from '../AdjustCameraPanel/AdjustCameraButton';
import { LoadingWrapper } from '@provider-components';
import { useFocusedRoom } from '../../../../hooks';
import { ConnectionMode } from '@provider-types/enums';

import HearingIcon from '@mui/icons-material/Hearing';
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';
import { AlarmToggleButton } from '../AlarmButtons/AlarmToggleButton';
import { useTranslation } from 'react-i18next';

export const InterventionModePanel = React.memo((): React.ReactElement => {
  const { focusedRoom, onFocusClick } = useFocusedRoom();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const callId = useSelector(getCallId);
  const isCameraControlActive = useSelector(selectIsCameraControlActive);

  const isAudioControlActive = useSelector(selectIsAudioControlActive);
  const selectedDevice = useSelector(getSelectedDevice);
  const interactiveDevice = useSelector(selectInteractiveDevice);

  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const isMyMicActive = useSelector(state => selectIsMicActive(state, selectedDeviceId));
  const isInteractiveMode = useSelector(state => selectIsDeviceInInteractiveMode(state, selectedDeviceId));

  const isListenMode = useSelector(state => selectIsDeviceInListenMode(state, selectedDeviceId));
  const listenModeDevices = useSelector(selectListenModeDevices);
  const listenModeDevicesStorageWhileIntervention = useSelector(selectListenModeDevicesStorageWhileIntervention);

  const selectedPaused = useSelector(selectSelectedDevicePaused);

  const isInterventionConnecting = useSelector(selectIsInterventionConnecting);
  const isInterventionDisconnecting = useSelector(selectIsInterventionDisconnecting);
  const interventionConnectionDeviceId = useSelector(selectInterventionConnectionDeviceId);

  const allowInterventionActionsWithThisDevice = useSelector(selectSelectedDeviceCanStartIntervention);
  const privacyMode = useSelector(state => selectIsDeviceInPrivacyMode(state, selectedDeviceId));
  const isDeviceControlDisable = useSelector(state => selectIsDeviceControlDisable(state, selectedDeviceId));

  const isListenModeConnecting = useSelector(selectIsListenModeConnecting);
  const isListenModeDisconnecting = useSelector(selectIsListenModeDisconnecting);
  const listenModeConnectionDeviceId = useSelector(selectListenModeConnectionDeviceId);

  const onAdjustAudioButton = useCallback(() => {
    console.log('onAdjustAudioButton: ', focusedRoom, selectedDevice, isCameraControlActive);
    if (focusedRoom !== selectedDeviceId) {
      onFocusClick(selectedDeviceId);
    }
    if (isCameraControlActive) {
      dispatch(setIsCameraControlActive(false));
    }
    if (isAudioControlActive === selectedDeviceId) {
      dispatch(setIsAudioControlActive(false));
    } else {
      dispatch(setIsAudioControlActive(selectedDeviceId));
    }
  }, [
    dispatch,
    focusedRoom,
    isAudioControlActive,
    isCameraControlActive,
    onFocusClick,
    selectedDevice,
    selectedDeviceId
  ]);

  const handleStartInterventionClick = useCallback(() => {
    if (selectedDevice) {
      dispatch(
        setInterventionConnectionStatus({ connecting: true, disconnecting: false, deviceId: selectedDevice.deviceId })
      );
    }

    if (interactiveDevice) {
      dispatch(handleChangeMode(interactiveDevice));
    }
    dispatch(setIsMicActive({ deviceId: selectedDeviceId, isMicActive: true }));
    dispatch(handleChangeMode(selectedDevice, ConnectionMode.INTERACTIVE));

    dispatch(setListenModeDevices([...listenModeDevices]));

    listenModeDevices
      .filter(device => device.deviceId !== selectedDevice?.deviceId)
      .forEach(device => dispatch(handleChangeMode(device, ConnectionMode.OBSERVATION)));
  }, [dispatch, interactiveDevice, selectedDevice, listenModeDevices, selectedDeviceId]);

  const handleStartListeningClick = useCallback(() => {
    if (selectedDevice) {
      dispatch(
        setListenModeConnectionStatus({ connecting: true, disconnecting: false, deviceId: selectedDevice.deviceId })
      );
      dispatch(setIsMicActive({ deviceId: selectedDevice.deviceId, isMicActive: true }));
    }

    dispatch(handleChangeMode(selectedDevice, ConnectionMode.LISTENING));
  }, [dispatch, selectedDevice]);

  const handleStopListeningClick = useCallback(() => {
    if (selectedDevice) {
      dispatch(
        setListenModeConnectionStatus({ connecting: false, disconnecting: true, deviceId: selectedDevice.deviceId })
      );
      dispatch(setIsMicActive({ deviceId: selectedDevice.deviceId, isMicActive: false }));
    }

    dispatch(handleChangeMode(selectedDevice, ConnectionMode.OBSERVATION));
  }, [dispatch, selectedDevice]);

  const handleMuteUnmuteAudioClick = useCallback(() => {
    dispatch(setIsMicActive({ deviceId: selectedDeviceId, isMicActive: !isMyMicActive }));
  }, [dispatch, isMyMicActive, selectedDeviceId]);

  const handleEndInterventionClick = useCallback(() => {
    if (selectedDevice) {
      dispatch(
        setInterventionConnectionStatus({ connecting: false, disconnecting: true, deviceId: selectedDevice.deviceId })
      );
      dispatch(setIsMicActive({ deviceId: selectedDevice.deviceId, isMicActive: false }));
    }

    dispatch(handleChangeMode(selectedDevice));

    listenModeDevicesStorageWhileIntervention
      .filter(device => device.deviceId !== selectedDevice?.deviceId)
      .forEach(device => dispatch(handleChangeMode(device, ConnectionMode.LISTENING)));

    dispatch(setListenModeDevices([]));
  }, [dispatch, selectedDevice, listenModeDevicesStorageWhileIntervention]);

  if (!isInteractiveMode) {
    return (
      <Stack direction={'column'} spacing={4} sx={{ my: 4, ml: 1 }}>
        <LoadingWrapper
          isLoading={isInterventionConnecting && interventionConnectionDeviceId === selectedDeviceId}
          loadingText={'Connecting'}
        >
          <TextButton
            fullWidth
            title={t('esitter.components.SidePanel.InterventionButton.start')}
            color={'success'}
            disabled={!allowInterventionActionsWithThisDevice || isListenModeConnecting || isListenModeDisconnecting}
            icon={LocalPhoneIcon}
            onClick={handleStartInterventionClick}
          />
        </LoadingWrapper>

        <LoadingWrapper
          isLoading={
            (isListenModeConnecting || isListenModeDisconnecting) && listenModeConnectionDeviceId === selectedDeviceId
          }
          loadingText={isListenModeConnecting ? 'Connecting' : 'Disconnecting'}
          sx={{ marginTop: '10px' }}
        >
          {isListenMode && (
            <TextButton
              fullWidth
              color={'success'}
              title='Patient’s Mic Sensitivity'
              icon={VolumeUpIcon}
              onClick={onAdjustAudioButton}
              labelSx={{ marginTop: '10px' }}
            />
          )}

          {!isListenMode ? (
            <TextButton
              fullWidth
              title={t('esitter.components.SidePanel.ListenButton.start')}
              color={'success'}
              icon={HearingIcon}
              disabled={Boolean(interactiveDevice || isInterventionConnecting || isDeviceControlDisable)}
              onClick={handleStartListeningClick}
              labelSx={{ marginTop: '10px' }}
            />
          ) : (
            <TextButton
              fullWidth
              title={t('esitter.components.SidePanel.ListenButton.end')}
              color={'error'}
              icon={HearingDisabledIcon}
              onClick={handleStopListeningClick}
              labelSx={{ marginTop: '10px' }}
            />
          )}
        </LoadingWrapper>
        <AlarmToggleButton
          isInteractiveMode={isInteractiveMode}
          deviceId={selectedDeviceId}
          callId={callId}
          disabled={Boolean(isDeviceControlDisable || isInterventionConnecting || (isInteractiveMode && privacyMode))}
        />
      </Stack>
    );
  }

  return (
    <>
      <Stack
        data-testid='interventionBlock'
        direction={'column'}
        spacing={4}
        sx={{ m: '20px 0px 2px -16px !important' }}
      >
        <Box sx={{ ml: '16px !important' }}>
          <IconWithText
            sx={{
              flexDirection: 'row-reverse',
              justifyContent: 'space-between'
            }}
            text={t('global.intervention')}
            variant={'h4'}
            icon={LocalPhoneIcon}
            iconSx={{ color: theme.palette.success.main, fontSize: '16px', mx: '4px' }}
            labelSx={{ color: theme.palette.success.main, pl: 0, pr: 2 }}
          />
        </Box>
        <TextButton
          fullWidth
          color={'secondary'}
          title={t(isMyMicActive ? 'audioControl.mute' : 'audioControl.unmute')}
          disabled={!allowInterventionActionsWithThisDevice}
          icon={isMyMicActive ? MicIcon : MicOffOutlinedIcon}
          iconSx={
            isMyMicActive
              ? undefined
              : {
                  '& > *:first-of-type': {
                    color: '#D93B3D'
                  }
                }
          }
          onClick={handleMuteUnmuteAudioClick}
        />
        <AlarmToggleButton
          isInteractiveMode={isInteractiveMode}
          deviceId={selectedDeviceId}
          callId={callId}
          disabled={Boolean(isDeviceControlDisable || isInterventionConnecting || (isInteractiveMode && privacyMode))}
        />
        <AdjustCameraButton />
        <TextButton
          fullWidth
          color={isAudioControlActive ? 'primary' : 'secondary'}
          title={t('esitter.components.SidePanel.PatientAudioButton')}
          disabled={!allowInterventionActionsWithThisDevice}
          icon={VolumeUpIcon}
          onClick={onAdjustAudioButton}
        />
        <Box sx={{ mb: '24px', ml: -3 }}>
          <LoadingWrapper
            isLoading={isInterventionDisconnecting && interventionConnectionDeviceId === selectedDeviceId}
            loadingText={'Disconnecting'}
          >
            <TextButton
              fullWidth
              title={t('esitter.components.SidePanel.InterventionButton.end')}
              disabled={selectedPaused}
              color={'error'}
              icon={CallIcon}
              iconSx={{ transform: 'rotate(135deg)' }}
              onClick={handleEndInterventionClick}
            />
          </LoadingWrapper>
        </Box>
      </Stack>
    </>
  );
});
