import { selectIsDeviceControlDisable } from '@provider-selectors/esitter';
import { useEsitterWSMessages } from '@provider-features/esitter/hooks/useEsitterWSMessages/useEsitterWSMessages';
import { BlurModeStatus } from '@provider-types/enums';
import { UseBlurMode } from '@provider-types/blurMode';

import { useSelector } from 'react-redux';
import { useCallback } from 'react';

export function useBlurMode(deviceId: string, callId: string, status: BlurModeStatus | null): UseBlurMode {
  const { sendDeviceBlurModeStateWSMessage } = useEsitterWSMessages(deviceId, callId);

  const isDeviceControlDisable = useSelector(state => selectIsDeviceControlDisable(state, deviceId));

  const isEnabled = status === BlurModeStatus.ENABLED;
  const isDisabled = status === BlurModeStatus.DISABLED;

  const enableBlurMode = useCallback(
    (onBeforeSendWSMessage?: () => void) => {
      onBeforeSendWSMessage?.();
      sendDeviceBlurModeStateWSMessage({ enabled: true });
    },
    [sendDeviceBlurModeStateWSMessage]
  );

  const disableBlurMode = useCallback(
    (onBeforeSendWSMessage?: () => void) => {
      onBeforeSendWSMessage?.();
      sendDeviceBlurModeStateWSMessage({ enabled: false });
    },
    [sendDeviceBlurModeStateWSMessage]
  );

  const toggleBlurMode = useCallback(() => {
    if (isEnabled) {
      disableBlurMode();
    } else if (isDisabled) {
      enableBlurMode();
    }
  }, [isEnabled, isDisabled, disableBlurMode, enableBlurMode]);

  return {
    isEnabled,
    isDisabled,
    isButtonDisabled: isDeviceControlDisable,
    enableBlurMode,
    disableBlurMode,
    toggleBlurMode
  };
}
