import { rebootDevice as rebootDeviceApi } from '@provider-api/esitter';
import { setDeviceRebootState } from '@provider-reducers/esitterSlice';
import { setToastData, setToastOpen } from '@provider-reducers/toastSlice';
import {
  selectDeviceName,
  selectDeviceRebootState as selectDeviceRebootStateEsitter
} from '@provider-selectors/esitter';
import { selectDeviceRebootState as selectDeviceRebootStateVR } from '@provider-selectors/vrcall';
import { DeviceRebootState, ECallType, EToastStatus } from '@provider-types/enums';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export type Props = {
  type: ECallType;
  callId: string;
  deviceId: string;
};

type Returns = {
  rebootDevice: () => void;
  resetRebootDevice: (withToast?: boolean) => void;
  isDeviceRebooting: boolean;
  isDeviceRebootingFailed: boolean;
};

export default function useRebootDevice({ callId, deviceId, type }: Props): Returns {
  const dispatch = useDispatch();
  const vRDeviceRebootState = useSelector(selectDeviceRebootStateVR);
  const eSitterDeviceRebootState = useSelector(state => selectDeviceRebootStateEsitter(state, deviceId));
  const eSitterDeviceName = useSelector(state => selectDeviceName(state, deviceId));

  const { t } = useTranslation();
  const failedMessageTranslation = t(type === ECallType.VR ? 'vrcall.rebootedFail' : 'esitter.rebootedFail', {});

  const isDeviceRebooting =
    (type === ECallType.VR ? vRDeviceRebootState : eSitterDeviceRebootState) === DeviceRebootState.REBOOT;
  const isDeviceRebootingFailed =
    (type === ECallType.VR ? vRDeviceRebootState : eSitterDeviceRebootState) === DeviceRebootState.FAIL;

  const commonReboot = useCallback(async () => {
    if (!isDeviceRebooting) {
      rebootDeviceApi(callId, deviceId);
    }
  }, [callId, deviceId, isDeviceRebooting]);

  const resetRebootStateVR = useCallback(
    (withToast?: boolean) => {
      dispatch({
        type: 'SET_DEVICE_REBOOT_STATE',
        payload: DeviceRebootState.ALIVE
      });

      if (withToast) {
        dispatch(
          setToastData({
            status: EToastStatus.SUCCESS,
            message: t('vrcall.rebootedSuccessfully'),
            autoHideDuration: 4000,
            highPosition: true
          })
        );
        dispatch(setToastOpen(true));
      }
    },
    [dispatch, t]
  );

  useEffect(() => {
    if (isDeviceRebootingFailed) {
      dispatch(
        setToastData({
          status: EToastStatus.ERROR,
          message: failedMessageTranslation,
          autoHideDuration: 4000,
          highPosition: true
        })
      );

      dispatch(setToastOpen(true));
    }
  }, [dispatch, isDeviceRebootingFailed, failedMessageTranslation]);

  const resetRebootStateEsitter = useCallback(
    (withToast?: boolean) => {
      dispatch(
        setDeviceRebootState({
          id: deviceId,
          rebootState: DeviceRebootState.ALIVE
        })
      );

      if (withToast) {
        dispatch(
          setToastData({
            status: EToastStatus.SUCCESS,
            message: t('esitter.rebootedSuccessfully', { device: eSitterDeviceName }),
            autoHideDuration: 4000,
            highPosition: true
          })
        );
        dispatch(setToastOpen(true));
      }
    },
    [deviceId, dispatch, t, eSitterDeviceName]
  );

  return {
    rebootDevice: commonReboot,
    resetRebootDevice: type === ECallType.VR ? resetRebootStateVR : resetRebootStateEsitter,
    isDeviceRebooting,
    isDeviceRebootingFailed
  };
}
