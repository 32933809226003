import { useCallback, useEffect, useState } from 'react';

export type ShortcutConfig = {
  keys: string[];
  callback: () => void;
};

export const useKeyboardShortcut = ({ keys, callback }: ShortcutConfig): void => {
  const [pressedKeys, setPressedKeys] = useState<string[]>([]);

  const handleKeyDown = (event: KeyboardEvent) => {
    const target = event.target as Element;
    const key = event.key.toLowerCase();

    // Prevent triggering shortcuts when typing in input
    if (target?.className?.includes('input')) return;

    if (!pressedKeys.includes(key)) {
      setPressedKeys([...pressedKeys, key]);
    }
  };

  const isShortcutActive = useCallback(() => pressedKeys.sort().join('') === keys.sort().join(''), [pressedKeys, keys]);

  useEffect(() => {
    if (isShortcutActive()) {
      callback();
      setPressedKeys([]);
    }
  }, [isShortcutActive, callback]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);
};
