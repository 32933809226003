import { TooltipProps } from '@mui/material/Tooltip';

export const VIRTUAL_BEDRAILS_AVAILABLE_ID = 1000;
export const AUDIO_LEVEL_AVAILABLE_ID = 1001;
export const PRERECORDED_MESSAGES_AVAILABLE_ID = 1002;

export const TEST_DEVICE_PREFFIX = 'TR-';
export const TEST_CAMERA_PREFFIX = 'camerauuid';

export const SHORTCUT_TOOLTIP_PROPS: Omit<TooltipProps, 'children' | 'title'> = {
  enterDelay: 1000,
  enterNextDelay: 1000,
  placement: 'top'
};
