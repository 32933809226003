import MicIcon from '@mui/icons-material/Mic';
import VideocamIcon from '@mui/icons-material/Videocam';
import React from 'react';
import { makeThemedStyles } from '@provider-hooks';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import Stack from '@mui/material/Stack';
import { ParticipantMenuButton } from '../ParticipantMenuButton/ParticipantMenuButton';

interface ParticipantRowActionsProps {
  isHovered: boolean;
  isVideoAvailable: boolean;
  isAudioAvailable: boolean;
  isDevice: boolean;
  isDeviceControlDisabled: boolean;
  amIHost: boolean;
  isHost: boolean;
  isBrowserTypeMobile: boolean;
  uuid: string;
  onMenuClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    uuid: string,
    amIHost: boolean,
    isHost: boolean,
    isAudioAvailable: boolean,
    isDevice: boolean,
    isBrowserTypeMobile: boolean
  ) => void;
  isMenuOpened: boolean;
}

export const ParticipantRowIndicators = (props: ParticipantRowActionsProps): React.ReactElement | null => {
  const {
    isHovered,
    isDevice,
    isDeviceControlDisabled,
    isAudioAvailable,
    isVideoAvailable,
    amIHost,
    isHost,
    isBrowserTypeMobile,
    onMenuClick,
    uuid,
    isMenuOpened
  } = props;
  const { styles } = useStyles();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onMenuClick(event, uuid, amIHost, isHost, isAudioAvailable, isDevice, isBrowserTypeMobile);
  };

  if (isHovered || isMenuOpened) {
    if (amIHost && !isBrowserTypeMobile && ((isDevice && !isDeviceControlDisabled) || (!isDevice && !isHost))) {
      return <ParticipantMenuButton isMenuOpened={isMenuOpened} onClick={handleMenuClick} />;
    }
    return null;
  }

  return (
    <Stack direction={'row'} spacing={4}>
      {isAudioAvailable && (!isDevice || (isDevice && !isDeviceControlDisabled)) ? (
        <MicIcon sx={styles.icon} data-testid='mic-on-icon' />
      ) : (
        <MicOffOutlinedIcon sx={styles.icon} data-testid='mic-off-icon' />
      )}
      {isVideoAvailable && (!isDevice || (isDevice && !isDeviceControlDisabled)) ? (
        <VideocamIcon sx={styles.icon} data-testid='cam-on-icon' />
      ) : (
        <VideocamOffOutlinedIcon sx={styles.icon} data-testid='cam-off-icon' />
      )}
    </Stack>
  );
};

const useStyles = makeThemedStyles()(() => ({
  icon: {
    color: '#ffffff'
  }
}));
