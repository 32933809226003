import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { IDeviceRoomInfo } from '@provider-types/provider';
import { selectFocusedRoom, selectRoomsArray, getSelectedDevice } from '@provider-selectors/esitter';

export const useKeyboardNavigation = (
  rows = 1,
  columns = 1,
  numberOfRoomBoxes = 1
): { itemsRef: React.MutableRefObject<HTMLDivElement[]> } => {
  const itemsRef = useRef<HTMLDivElement[]>([]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [locallySavedSelectedDevice, setLocallySavedSelectedDevice] = useState<IDeviceRoomInfo | null>(null);

  const focusedRoom = useSelector(selectFocusedRoom);
  const roomsArray = useSelector(selectRoomsArray);
  const selectedDevice = useSelector(getSelectedDevice);

  // FIXME: MM's temp, quick solution, need to check useContainerResize hook
  const boxesAmount = focusedRoom ? numberOfRoomBoxes + 1 : numberOfRoomBoxes;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key, altKey } = event;
      let nextIndex = activeIndex;

      if (!altKey) return;

      switch (key) {
        case 'ArrowRight':
          nextIndex = (activeIndex + 1) % boxesAmount;
          break;
        case 'ArrowLeft':
          nextIndex = (activeIndex - 1 + boxesAmount) % boxesAmount;
          break;
        case 'ArrowUp':
          nextIndex = (activeIndex - columns + boxesAmount) % boxesAmount;
          break;
        case 'ArrowDown': {
          nextIndex = (activeIndex + columns) % boxesAmount;
          break;
        }
        default:
          break;
      }

      if (itemsRef.current[nextIndex]) {
        itemsRef.current[nextIndex].click();
      }

      setActiveIndex(nextIndex);
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeIndex, boxesAmount, rows, columns]);

  useEffect(() => {
    if (selectedDevice) {
      setLocallySavedSelectedDevice(selectedDevice);
    }
  }, [selectedDevice]);

  useEffect(() => {
    // handle drag n drop of the selected device
    if (selectedDevice) {
      const newIndex = roomsArray.findIndex(room => room.deviceId === selectedDevice.deviceId);
      if (newIndex !== -1) {
        setActiveIndex(newIndex);
      }
      // handle sorting, as there is no selected device when click on Sort By dropdown
    } else if (locallySavedSelectedDevice) {
      const newIndex = roomsArray.findIndex(room => room.deviceId === locallySavedSelectedDevice.deviceId);
      if (newIndex !== -1) {
        setActiveIndex(newIndex);
      }
    } else {
      setActiveIndex(0);
    }
  }, [roomsArray, selectedDevice, locallySavedSelectedDevice]);

  useEffect(() => {
    // handle delete room
    if (selectedDevice && !roomsArray.find(room => room.deviceId === selectedDevice.deviceId)) {
      setActiveIndex(prevIndex => (prevIndex + 1) % roomsArray.length);
    }
  }, [roomsArray, selectedDevice]);

  return { itemsRef };
};
