import React from 'react';
import { createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import { NotFoundRoutes, NotFoundRouteNames } from '@provider-features/notfound/router';
import { DeviceRouteNames, DeviceRoutes } from '@provider-features/devices/router';
import { VRCallRoutes } from '@provider-features/vrcall/router';
import { AuthRoutes } from '@provider-features/auth/router';
import { ESitterRoutes } from '@provider-features/esitter/router';
import { EmbedRoutes } from '@provider-features/embed/router';
import { RequireAuth } from '@provider-components';

const Router: React.FC<EmptyProps> = () => {
  const router = createHashRouter([
    {
      path: '/',
      element: (
        <RequireAuth>
          <Navigate to={DeviceRouteNames.AdminDevices} />
        </RequireAuth>
      )
    },
    ...NotFoundRoutes,
    ...AuthRoutes,
    ...DeviceRoutes,
    ...ESitterRoutes,
    ...VRCallRoutes,
    ...EmbedRoutes,
    {
      path: '*',
      element: <Navigate to={NotFoundRouteNames.NotFound} />
    }
  ]);

  return <RouterProvider router={router} fallbackElement={<Navigate to={NotFoundRouteNames.NotFound} />} />;
};

export default Router;
