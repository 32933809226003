import { BackendError } from '@provider-errors/BackendError';

interface IDefaultRequestParams {
  headers?: Record<string, string>;
  body: BodyInit | object;
}

class Api {
  private _baseURL: string;
  private _headers: Headers;

  constructor(settings?: { baseURL?: string; headers?: Record<string, string> }) {
    this._baseURL = settings?.baseURL || '';
    this._headers = this._setHeaders(settings?.headers);
  }

  private _setHeaders(params?: Record<string, string>) {
    const headers = new Headers(this._headers);

    for (const prop in params) {
      const val = params[prop];

      headers.set(prop, val);
    }

    return headers;
  }

  private async _parseBodyResponse<R>(res: Response): Promise<R> {
    const contentType = res.headers.get('content-type');
    const text = (await res.text()) as any;
    let data: any = null;

    if (contentType?.includes('text/plain')) {
      if (res.ok) {
        return text as R;
      } else {
        throw new BackendError(res.statusText, res.status, null);
      }
    }

    try {
      data = JSON.parse(text);
    } catch (err) {
      throw new BackendError(text, res.status, null);
    }

    if (!res.ok) {
      throw new BackendError(res.statusText, res.status, data);
    }

    return data as R;
  }

  async get<R = unknown>(path: string, params?: Omit<IDefaultRequestParams, 'body'>) {
    const res = await fetch(this._baseURL + path, {
      method: 'GET',
      headers: this._setHeaders(params?.headers)
    });

    const body = await this._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async post<R = unknown>(path: string, params: IDefaultRequestParams) {
    const res = await fetch(this._baseURL + path, {
      method: 'POST',
      body: JSON.stringify(params.body),
      headers: this._setHeaders(params.headers)
    });

    const body = await this._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async patch<R = unknown>(path: string, params: IDefaultRequestParams) {
    const res = await fetch(this._baseURL + path, {
      method: 'PATCH',
      body: JSON.stringify(params.body),
      headers: this._setHeaders(params.headers)
    });

    const body = await this._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }

  async delete<R = unknown>(path: string, params?: Omit<IDefaultRequestParams, 'body'>) {
    const res = await fetch(this._baseURL + path, {
      method: 'DELETE',
      headers: this._setHeaders(params?.headers)
    });

    const body = await this._parseBodyResponse<R>(res);

    return { body, headers: res.headers };
  }
}

const api = new Api({ headers: { 'Content-Type': 'application/json; charset=utf-8' } });

export default api;
