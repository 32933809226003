import { Box, Stack, Typography } from '@mui/material';
import React, { ElementRef } from 'react';
import format from 'date-fns/format';

type Props = LogEventEntry & {
  icon?: React.ReactElement;
};

const dateFormat = 'hh:mm:ss a, P';

const LogEntry = React.forwardRef<ElementRef<'div'>, Props>(
  ({ icon, title, timestamp, description, ...rest }, ref): React.ReactElement => {
    return (
      <Box ref={ref} {...rest}>
        <Stack
          direction={'row'}
          sx={{
            display: 'flex',
            gap: 3
          }}
        >
          {icon}

          <Stack spacing={0.25}>
            <Typography variant='subtitle1' sx={{ fontSize: '14px' }}>
              {title}
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '12px', color: ({ palette }) => palette.text.secondary }}>
              {description}
            </Typography>
            <Typography
              data-testid='logItemDate'
              variant='body1'
              sx={{ fontSize: '12px', color: ({ palette }) => palette.text.secondary }}
            >
              {format(timestamp, dateFormat)}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  }
);

export default LogEntry;
