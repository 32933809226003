import React from 'react';
import { Snackbar, SvgIcon } from '@mui/material';
import styles from './Toast.module.css';
// Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { EToastStatus } from '@provider-types/enums';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import { makeThemedStyles } from '@provider-hooks';
import { LoadingCircularProgress } from '../LoadingCircularProgress/LoadingCircularProgress';
import { Box, useMediaQuery } from '@mui/material';

export interface ToastProps {
  open: boolean;
  status: EToastStatus;
  message: string;
  actionText: string;
  autoHideDuration?: number | null;
  highPosition?: boolean;
  onClose: (event: React.SyntheticEvent<any> | Event, reason: string) => void;
  onActionClick?: () => void;
}

interface ToastSettings {
  icon: typeof SvgIcon | null;
  primaryColor: string;
  secondaryColor: string;
  secondaryTintColor: string;
  btnTextColor: string;
}

type ToastStatusMap = Record<EToastStatus, ToastSettings>;

export const Toast = React.memo((props: ToastProps): React.ReactElement => {
  const { open, status, message, actionText, autoHideDuration, highPosition = false, onClose, onActionClick } = props;

  const statusMap: ToastStatusMap = {
    [EToastStatus.INFO]: {
      icon: InfoOutlinedIcon,
      primaryColor: '#1774CC',
      secondaryColor: '#FFFFFF',
      secondaryTintColor: '#1774CC',
      btnTextColor: '#FFFFFF'
    },
    [EToastStatus.ERROR]: {
      icon: InfoOutlinedIcon,
      primaryColor: '#FFFFFF',
      secondaryColor: '#D93B3D',
      secondaryTintColor: '#FF787A',
      btnTextColor: '#FFFFFF'
    },
    [EToastStatus.WARNING]: {
      icon: WarningAmberIcon,
      primaryColor: '#141414',
      secondaryColor: '#EF9C23',
      secondaryTintColor: '#FFAE38',
      btnTextColor: '#141414'
    },
    [EToastStatus.SUCCESS]: {
      icon: CheckCircleOutlineIcon,
      primaryColor: '#FFFFFF',
      secondaryColor: '#00857A',
      secondaryTintColor: '#00E5D2',
      btnTextColor: '#141414'
    },
    [EToastStatus.STARTINTERACTION]: {
      icon: VideocamIcon,
      primaryColor: '#1A1F24',
      secondaryColor: '#FFFFFF',
      secondaryTintColor: '#1A1F24',
      btnTextColor: '#FFFFFF'
    },
    [EToastStatus.MUTEALL]: {
      icon: MicOffOutlinedIcon,
      primaryColor: '#1A1F24',
      secondaryColor: '#FFFFFF',
      secondaryTintColor: '#1A1F24',
      btnTextColor: '#FFFFFF'
    },
    [EToastStatus.MAKEHOST]: {
      icon: PermContactCalendarOutlinedIcon,
      primaryColor: '#1A1F24',
      secondaryColor: '#FFFFFF',
      secondaryTintColor: '#1A1F24',
      btnTextColor: '#FFFFFF'
    },
    [EToastStatus.OFFLINE]: {
      icon: InfoOutlinedIcon,
      primaryColor: '#1A1F24',
      secondaryColor: '#FFFFFF',
      secondaryTintColor: '#1A1F24',
      btnTextColor: '#FFFFFF'
    },
    [EToastStatus.LOADING]: {
      icon: null,
      primaryColor: '#141414',
      secondaryColor: '#EF9C23',
      secondaryTintColor: '#FFAE38',
      btnTextColor: '#141414'
    }
  };

  const toastSettings: ToastSettings = statusMap[status] || {
    icon: InfoOutlinedIcon,
    primaryColor: '#FFFFFF',
    secondaryColor: '#FFFFFF',
    secondaryTintColor: '#FFFFFF',
    btnTextColor: '#FFFFFF'
  };

  const { icon: MuiIcon, primaryColor, secondaryColor, secondaryTintColor, btnTextColor } = toastSettings;
  const isMediumDeviceHeight = useMediaQuery('(max-height: 930px)');
  const { styles: sx } = useStyles({ highPosition, primaryColor, secondaryColor, isMediumDeviceHeight });

  const iconComponent =
    status === EToastStatus.LOADING ? <LoadingCircularProgress /> : MuiIcon && <MuiIcon sx={sx.icon} />;

  // `key` to ensure autoHideDuration is not reused when a new message is provided, but
  // autoHideDuration is not changing

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      key={message}
      autoHideDuration={autoHideDuration}
      sx={sx.snackbar}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <Box sx={sx.messageContainer}>
        <Box sx={sx.messageIconContainer}>{iconComponent}</Box>
        <Box sx={sx.messageText}>{message}</Box>
        {onActionClick && (
          <button
            className={styles.messageActionBtn}
            style={{ backgroundColor: secondaryTintColor, color: btnTextColor }}
            onClick={onActionClick}
          >
            {actionText}
          </button>
        )}
      </Box>
    </Snackbar>
  );
});

type StyleProps = {
  highPosition?: boolean;
  primaryColor: string;
  secondaryColor: string;
  isMediumDeviceHeight: boolean;
};

const useStyles = makeThemedStyles<StyleProps>()(
  (_, { highPosition, primaryColor, secondaryColor, isMediumDeviceHeight }) => ({
    messageContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      padding: '8px 16px',
      borderRadius: '32px',
      backgroundColor: secondaryColor
    },
    messageIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '24px',
      height: '24px'
    },
    messageText: {
      whiteSpace: 'pre-line',
      maxWidth: '505px',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      fontWeight: 400,
      fontFamily: 'Open Sans',
      color: primaryColor
    },
    snackbar: {
      marginBottom: highPosition ? '72px' : '0',
      transition: 'all 0.5s ease-in-out',
      position: 'absolute',
      bottom: isMediumDeviceHeight ? '0 !important' : '24px'
    },
    icon: {
      color: primaryColor,
      width: '20px',
      height: '20px'
    }
  })
);
