import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsSettingUp,
  selectEnabled,
  selectIsAutoPlacementExpired,
  selectIsSmartRailsPolygonEmpty,
  selectSensitivityLevel,
  selectSmartRailsGenerationType
} from '@provider-selectors/smartRails';
import { setBoundaryPoints, setIsAutoPlacementExpired, setIsSettingUp } from '@provider-reducers/smartRailsSlice';
import { getCallId } from '@provider-selectors/esitter';
import { useEsitterWSMessages } from '../useEsitterWSMessages/useEsitterWSMessages';
import { getDefaultCoordinates } from '@provider-features/esitter/functions';
import { setToastData, setToastOpen } from '@provider-reducers/toastSlice';
import { EToastStatus, PolygonGeneratedType } from '@provider-types/enums';
import { useTranslation } from 'react-i18next';

type Props = {
  deviceId: string;
  cameraUuid: string;
};

const TIMEOUT = 12 * 1000;

export default function useSmartRailsExpirationHandler({ cameraUuid, deviceId }: Props): void {
  const isSettingUp = useSelector(state => getIsSettingUp(state, cameraUuid));
  const isExpired = useSelector(state => selectIsAutoPlacementExpired(state, cameraUuid));
  const isPolygonEmpty = useSelector(state => selectIsSmartRailsPolygonEmpty(state, cameraUuid));
  const boundaryEnabled = useSelector(state => selectEnabled(state, cameraUuid));
  const sensitivityLevel = useSelector(state => selectSensitivityLevel(state, cameraUuid));
  const smartRailsGenerationType = useSelector(state => selectSmartRailsGenerationType(state, cameraUuid));
  const callId = useSelector(getCallId);
  const { sendUpdateSmartRailsEnabledWSMessage } = useEsitterWSMessages(deviceId, callId);

  const autoSmartRailsTimerRef = useRef<ReturnType<typeof setTimeout> | null>();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (smartRailsGenerationType === PolygonGeneratedType.DEFAULT) {
      dispatch(
        setToastData({
          status: EToastStatus.WARNING,
          message: t('smartRails.defaultPolygonsWarning'),
          autoHideDuration: 5000
        })
      );
      dispatch(setToastOpen(true));
    }
  }, [dispatch, smartRailsGenerationType, t]);

  /** this should be last resort to set polygon after expiration and fail to get points from device */
  useEffect(() => {
    if (isPolygonEmpty && isExpired) {
      const defaultPoints = getDefaultCoordinates();

      sendUpdateSmartRailsEnabledWSMessage(cameraUuid, boundaryEnabled, sensitivityLevel, defaultPoints);
      dispatch(setBoundaryPoints({ cameraUuid, points: defaultPoints }));
    }
  }, [
    boundaryEnabled,
    cameraUuid,
    dispatch,
    isExpired,
    isPolygonEmpty,
    sendUpdateSmartRailsEnabledWSMessage,
    sensitivityLevel
  ]);

  /** reset isSettingUp when timeout exprited */
  useEffect(() => {
    if (isExpired) {
      dispatch(setIsSettingUp({ cameraUuid, isSettingUp: false }));
      dispatch(
        setToastData({
          status: EToastStatus.WARNING,
          message: t('smartRails.autoPlacementFailed'),
          autoHideDuration: 5000
        })
      );
      dispatch(setToastOpen(true));
      autoSmartRailsTimerRef.current = null;
    }
  }, [cameraUuid, dispatch, isExpired, t]);

  /** start timeout when isSettingUp starts */
  useEffect(() => {
    if (isSettingUp && !autoSmartRailsTimerRef.current) {
      dispatch(setIsAutoPlacementExpired({ cameraUuid, isExpired: false }));

      autoSmartRailsTimerRef.current = setTimeout(() => {
        dispatch(setIsAutoPlacementExpired({ cameraUuid, isExpired: true }));
        dispatch(setIsSettingUp({ cameraUuid, isSettingUp: false }));
        autoSmartRailsTimerRef.current = null;
      }, TIMEOUT);
    } else if (!isSettingUp && autoSmartRailsTimerRef.current) {
      clearTimeout(autoSmartRailsTimerRef.current);
      autoSmartRailsTimerRef.current = null;
    }
  }, [isSettingUp, cameraUuid, dispatch]);

  /** reset state when camera change */
  useEffect(() => {
    return () => {
      if (autoSmartRailsTimerRef.current) {
        clearTimeout(autoSmartRailsTimerRef.current);
        autoSmartRailsTimerRef.current = null;
        dispatch(setIsAutoPlacementExpired({ cameraUuid, isExpired: false }));
      }
    };
  }, [cameraUuid, dispatch]);
}
