import React from 'react';
import { Box, Stack } from '@mui/material';
import { GenderSelector } from './GenderSelector';
import { LanguageSelector } from './LanguageSelector';
import { MessageSelector } from '@provider-components';

import { getSelectedDeviceId, selectIsDeviceControlDisable } from '@provider-selectors/esitter';
import { useSelector } from 'react-redux';

export const PrerecordedMessages = (): React.ReactElement | null => {
  const deviceId = useSelector(getSelectedDeviceId);

  const isDeviceControlDisable = useSelector(state => selectIsDeviceControlDisable(state, deviceId));

  return (
    <Stack spacing={4} data-testid='prerecordedMessagesPanel'>
      <Box>
        <GenderSelector deviceId={deviceId} disabled={isDeviceControlDisable} />
      </Box>
      <Box>
        <LanguageSelector deviceId={deviceId} disabled={isDeviceControlDisable} />
      </Box>
      <Box>
        <MessageSelector deviceId={deviceId} disabled={isDeviceControlDisable} />
      </Box>
    </Stack>
  );
};
