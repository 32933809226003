import React from 'react';
import { SelectCameraDropdown } from '@provider-components';
import { useSelector } from 'react-redux';
import {
  getSelectedDeviceId,
  selectDeviceCameraList,
  selectHasReceivedWSMessage,
  selectIsDeviceControlDisable
} from '@provider-selectors/esitter';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCameraChange } from '@provider-hooks';
import Box from '@mui/material/Box';
import { AdjustCameraButton } from './AdjustCameraButton';
import { LoadingWrapper } from '@provider-components';
import { getIsSettingUp } from '@provider-selectors/smartRails';

export const AdjustCameraPanel = (): React.ReactElement | null => {
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const cameraList = useSelector(state => selectDeviceCameraList(state, selectedDeviceId));
  const isDeviceReceivedWSMessage = useSelector(state => selectHasReceivedWSMessage(state, selectedDeviceId));
  const isDeviceControlDisable = useSelector(state => selectIsDeviceControlDisable(state, selectedDeviceId));
  const { onCameraChange, selectedCameraUuid } = useCameraChange(selectedDeviceId);
  const isSmartRailsSettingUp = useSelector(state => getIsSettingUp(state, selectedCameraUuid));

  return (
    <>
      <LoadingWrapper isLoading={!(cameraList?.length && isDeviceReceivedWSMessage)} loadingText={'Loading...'}>
        <Stack spacing={2} data-testid='adjustCameraPanel' alignItems={'flex-start'}>
          <Typography variant='h4'>Camera</Typography>
          <SelectCameraDropdown
            cameraList={cameraList}
            selectedCameraUuid={selectedCameraUuid}
            onCameraChange={onCameraChange}
            selectSx={{
              fontSize: '12px',
              minWidth: '145px',
              width: '145px',

              borderRadius: '4px',
              '& .MuiSelect-select': {
                padding: '4px 8px'
              },
              '& .MuiSelect-icon': {
                color: ({ palette }) => palette.common.white,
                fontSize: '1rem',
                right: '7px'
              }
            }}
            zoomTextSx={{
              fontSize: 'inherit',
              lineHeight: 'inherit',
              letterSpacing: 'inherit',
              fontFamily: 'inherit',
              color: 'inherit'
            }}
            disabled={isDeviceControlDisable || isSmartRailsSettingUp}
          />
          <Box sx={{ ml: '-12px !important' }}>
            <AdjustCameraButton labelSx={{ textTransform: 'capitalize' }} />
          </Box>
        </Stack>
      </LoadingWrapper>
    </>
  );
};
