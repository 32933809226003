import { ButtonControl } from '@provider-components';
import { EButtonControlColor, EButtonControlSize } from '@provider-types/enums';
import GroupIcon from '@mui/icons-material/Group';
import React, { useCallback, useRef, ElementRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeThemedStyles, useKeyboardShortcut } from '@provider-hooks';
import { selectSidePanelCollapsed } from '@provider-selectors/esitter';
import { setSidePanelCollapsed } from '@provider-reducers/esitterSlice';
import { useTranslation } from 'react-i18next';
import { SHORTCUT_TOOLTIP_PROPS } from '@provider-constants';
import { KeyboardShortcutKey } from '@provider-types/enums';

export const CollapseButton = React.memo((): React.ReactElement => {
  const { t } = useTranslation();
  const sidePanelCollapsed = useSelector(selectSidePanelCollapsed);

  const patientDetailsBtnRef = useRef<ElementRef<'button'>>(null);

  const dispatch = useDispatch();
  const { styles } = useStyles({ sidePanelCollapsed });
  const handleClick = useCallback(
    () => dispatch(setSidePanelCollapsed(!sidePanelCollapsed)),
    [dispatch, sidePanelCollapsed]
  );

  const handlePatientDetailsShortcut = () => {
    patientDetailsBtnRef.current?.click();
  };

  useKeyboardShortcut({
    keys: [KeyboardShortcutKey.SIDE_PANEL],
    callback: handlePatientDetailsShortcut
  });

  return (
    <>
      <ButtonControl
        ref={patientDetailsBtnRef}
        size={EButtonControlSize.MEDIUM}
        backgroundColor={sidePanelCollapsed ? '#313A42' : '#1774CC'}
        color={sidePanelCollapsed ? EButtonControlColor.GRAY : EButtonControlColor.BLUE}
        onClick={handleClick}
        data-testid='myPatientsBtn'
        tipTitle={t('esitter.shortcutTooltips.myPatientsBtn')}
        tipProps={SHORTCUT_TOOLTIP_PROPS}
      >
        <GroupIcon sx={styles.icon} />
      </ButtonControl>
    </>
  );
});

type StyleProps = {
  sidePanelCollapsed: boolean;
};

const useStyles = makeThemedStyles<StyleProps>()((_, { sidePanelCollapsed }) => ({
  icon: {
    width: '19.86px',
    height: '19.86px',
    color: sidePanelCollapsed ? '#E2E2E2' : '#ffffff'
  }
}));
