import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HealthData } from '@provider-types/reducer';
import { AppDispatch, AppThunk } from '../root';

interface SetHealthDataPayload {
  healthData: HealthData;
}

interface RemoveHealthDataByIdPayload {
  deviceId: string;
}

export interface HealthDataState {
  healthDataArray: HealthData[];
}

const initialState: HealthDataState = {
  healthDataArray: []
};

const healthDataSlice = createSlice({
  name: 'healthData',
  initialState,
  reducers: {
    setHealthData: (state, action: PayloadAction<SetHealthDataPayload>) => {
      const { healthData } = action.payload;
      const indexToUpdate = state.healthDataArray.findIndex(d => d.deviceId === healthData.deviceId);
      if (indexToUpdate !== -1) {
        Object.assign(state.healthDataArray[indexToUpdate], healthData);
      } else {
        state.healthDataArray.push(healthData);
      }
    },
    removeHealthDataById: (state, action: PayloadAction<RemoveHealthDataByIdPayload>) => {
      const { deviceId } = action.payload;
      const index = state.healthDataArray.findIndex(d => d.deviceId === deviceId);
      if (index >= 0) {
        state.healthDataArray.splice(index, 1);
      }
    }
  }
});

const parseBioData = function (data: any) {
  const {
    code: {
      coding: [{ code: bioCode, display: bioName = '' }]
    },
    valueQuantity: { value: bioValue = 0, code: bioUnit = '' }
  } = data;

  return { code: bioCode, value: bioValue, name: bioName, unit: bioUnit };
};

enum LOINC {
  BLOOD_PRESSURE = '85354-9',
  BLOOD_PRESSURE_SYSTOLIC = '8480-6',
  BLOOD_PRESSURE_DIASTOLIC = '8462-4',
  HEART_RATE = '8867-4',
  BLOOD_GLUCOSE = '2339-0',
  BODY_WEIGHT = '29463-7',
  BMI = '39156-5',
  BODY_FAT = '41982-0',
  RMR = '69429-9'
}

const getHealthDataFromMessage = function (deviceId: string, data: any) {
  let healthData = { deviceId };
  const hippaData = JSON.parse(data);
  console.log(`@@[getHealthDataFromMessage] ${JSON.stringify(healthData)}`);

  // check if data is "blood pressure"
  if (hippaData[0].code.coding[0].code === LOINC.BLOOD_PRESSURE) {
    let bloodPressure = {};
    for (const data of hippaData[0].component) {
      const m = parseBioData(data);
      switch (m.code) {
        case LOINC.BLOOD_PRESSURE_SYSTOLIC:
          bloodPressure = Object.assign(bloodPressure, { systolic: m });
          break;
        case LOINC.BLOOD_PRESSURE_DIASTOLIC:
          bloodPressure = Object.assign(bloodPressure, { diastolic: m });
          break;
      }
    }
    const heartRate = parseBioData(hippaData[1]);
    healthData = Object.assign(healthData, { bloodPressure, heartRate });
    console.log(`@@[getHealthDataFromMessage : bloodpressure]${JSON.stringify(healthData)}`);
  } else {
    for (const data of hippaData) {
      const m = parseBioData(data);
      switch (m.code) {
        case LOINC.BLOOD_GLUCOSE:
          healthData = Object.assign(healthData, { bloodGlucose: m });
          break;
        case LOINC.BODY_WEIGHT:
          healthData = Object.assign(healthData, { bodyWeight: m });
          break;
        case LOINC.BMI:
          healthData = Object.assign(healthData, { bmi: m });
          break;
        case LOINC.BODY_FAT:
          healthData = Object.assign(healthData, { bodyFat: m });
          break;
        case LOINC.RMR:
          healthData = Object.assign(healthData, { rmr: m });
          break;
      }
    }
  }
  return healthData;
};

export const receiveHealthMeasurementMessage = (deviceId: string, measurements: string): AppThunk => {
  console.log(`@@receiveHealthMeasurementMessage deviceId = ${deviceId}`);
  return (dispatch: AppDispatch): void => {
    console.log(`@@getHealthDataFromMessage`);
    const healthData = getHealthDataFromMessage(deviceId, measurements);
    console.log(`@@[healthData] ${JSON.stringify(healthData)}`);
    dispatch(setHealthData({ healthData }));
  };
};

export const { setHealthData, removeHealthDataById } = healthDataSlice.actions;

export default healthDataSlice.reducer;
