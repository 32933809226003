import React, { useCallback, useEffect, useState } from 'react';
import MenuMUI from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import styles from './Menu.module.css';
import { MenuContent, MenuData, MenuSecondaryContent, MenuSecondaryData } from '@provider-types/components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PopoverOrigin, PopoverPosition } from '@mui/material';

export interface Props {
  data: MenuData[];
  secondaryData: MenuSecondaryData[];
  anchorEl: Element | null;
  positioning?: 'left' | 'right';
  onClose: () => void;
}

export const Menu: React.FC<Props> = ({ data, secondaryData, anchorEl, positioning = 'left', onClose }) => {
  const [selectedId, setSelectedId] = useState<string>();
  const [secondaryAnchorEl, setSecondaryAnchorEl] = useState<Element | null>(null);
  const secondaryOpen = Boolean(secondaryAnchorEl);
  const open = Boolean(anchorEl);

  const onSetSecondaryAnchorEl = useCallback(
    (id: string) => {
      const element = document.getElementById(id);
      setSecondaryAnchorEl(element);
    },
    [setSecondaryAnchorEl]
  );

  /**
   * Secondary menu overlaps the first one with default behaviour
   * So secondary overlay now specifically ignore pointer events, to allow interaction with previous menu
   * so cleanup function listens state change instead
   */
  useEffect(() => {
    if (!open) {
      setSecondaryAnchorEl(null);
      setSelectedId('');
    }
  }, [open]);

  if (!anchorEl) {
    return null;
  }

  let anchorPosition: PopoverPosition = {
    top: anchorEl.getBoundingClientRect().top + anchorEl.getBoundingClientRect().height,
    left: anchorEl.getBoundingClientRect().left + anchorEl.getBoundingClientRect().width + 23
  };
  let anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right'
  };
  let transformOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
  };
  //
  let secondaryAnchorPosition: PopoverPosition = secondaryAnchorEl
    ? {
        left: secondaryAnchorEl.getBoundingClientRect().left + secondaryAnchorEl?.getBoundingClientRect().width + 16,
        top: secondaryAnchorEl.getBoundingClientRect().top
      }
    : { left: 0, top: 0 };
  let secondaryAnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right'
  };
  let secondaryTransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left'
  };
  ///
  if (positioning === 'right') {
    anchorPosition = {
      left: anchorEl.getBoundingClientRect().left,
      top: anchorEl.getBoundingClientRect().top + anchorEl.getBoundingClientRect().height + 12
    };
    anchorOrigin = {
      vertical: 'top',
      horizontal: 'left'
    };
    transformOrigin = {
      vertical: 'top',
      horizontal: 'left'
    };
    //
    secondaryAnchorPosition = secondaryAnchorEl
      ? {
          left: secondaryAnchorEl.getBoundingClientRect().left - 9,
          top: secondaryAnchorEl.getBoundingClientRect().top
        }
      : { left: 0, top: 0 };
    secondaryAnchorOrigin = {
      vertical: 'top',
      horizontal: 'left'
    };
    secondaryTransformOrigin = {
      vertical: 'top',
      horizontal: 'right'
    };
  }

  return (
    <div>
      <MenuMUI
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorReference={'anchorPosition'}
        anchorPosition={anchorPosition}
        open={open}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        slotProps={{
          paper: {
            style: {
              width: '225px',
              backgroundColor: '#232A30',
              fontSize: '14px',
              lineHeight: '16px',
              letterSpacing: '0.25px',
              fontFamily: 'Open Sans',
              color: '#FFFFFF'
            }
          }
        }}
      >
        {data.map((_: MenuData) => (
          <div key={`data-${_.label}`}>
            <p className={styles.settingsTitle}>{_.label}</p>
            {_.content.map((select: MenuContent) => (
              <MenuItem
                id={select.id}
                key={`${_.label}-${select.title}`}
                sx={{ padding: '12px 16px', backgroundColor: select.id === selectedId ? '#313A42' : '#232A30' }}
                onClick={() => {
                  if (!select || !select.disableSecondaryContent) {
                    setSelectedId(select.id);
                    onSetSecondaryAnchorEl(select.id);
                  }
                  select.onClick && select.onClick();
                }}
              >
                <ListItemIcon>
                  <select.Icon sx={{ color: '#ffffff' }} />
                </ListItemIcon>
                {select.title}
              </MenuItem>
            ))}
          </div>
        ))}
      </MenuMUI>
      {secondaryAnchorEl && (
        <MenuMUI
          data-testid='long-menu-secondary'
          hideBackdrop
          slotProps={{
            root: {
              style: {
                pointerEvents: 'none'
              }
            },
            paper: {
              style: {
                width: '365px',
                backgroundColor: '#232A30',
                fontSize: '14px',
                lineHeight: '16px',
                letterSpacing: '0.25px',
                fontFamily: 'Open Sans',
                color: '#FFFFFF',
                pointerEvents: 'all'
              }
            }
          }}
          id='long-menu-secondary'
          MenuListProps={{
            'aria-labelledby': 'long-button'
          }}
          anchorReference={'anchorPosition'}
          anchorPosition={secondaryAnchorPosition}
          // anchorEl={secondaryAnchorEl}
          open={secondaryOpen}
          anchorOrigin={secondaryAnchorOrigin}
          transformOrigin={secondaryTransformOrigin}
        >
          {secondaryData
            .filter((_: MenuSecondaryData) => _.selectId === selectedId)
            .map((_: MenuSecondaryData) => (
              <div key={`secondarydata-s${_.label}`} data-testid={`${_.selectId}_list`}>
                <p className={styles.settingsTitle}>{_.label}</p>
                {_.content.map((select: MenuSecondaryContent, idx) => (
                  <MenuItem
                    data-testid={`${_.selectId}:listitem:${idx + 1}`}
                    key={`${_.label}-${select.title}`}
                    sx={{ padding: '12px 16px', whiteSpace: 'normal' }}
                    onClick={() => {
                      select.onClick();
                    }}
                  >
                    <ListItemIcon>
                      {select.selected ? (
                        <CheckCircleIcon sx={{ color: '#46AFF2' }} />
                      ) : (
                        select.Icon && <select.Icon sx={{ color: '#ffffff' }} />
                      )}
                    </ListItemIcon>
                    {select.title}
                  </MenuItem>
                ))}
              </div>
            ))}
        </MenuMUI>
      )}
    </div>
  );
};
