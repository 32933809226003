// Export for testing...
export enum ExperimentalFlag {
  AI_SCRIBE_RECORDING = 'EXPERIMENTAL_AI_SCRIBE_RECORDING'
}

export const isFlagActive = (flag: ExperimentalFlag): boolean => {
  return !!window.localStorage.getItem(flag);
};

export const isAiScribeFlagActive = isFlagActive(ExperimentalFlag.AI_SCRIBE_RECORDING);
