import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EPrerecordedMessageGender } from '@provider-types/enums';
import { setRoomMessageGender } from '@provider-reducers/esitterSlice';
import { selectAvailableRoomMessageGenderByLang, selectRoomMessageGender } from '@provider-selectors/esitter';

type Props = {
  deviceId: string;
  disabled?: boolean;
};

export const GenderSelector = ({ deviceId, disabled = false }: Props): React.ReactElement => {
  const gender = useSelector(state => selectRoomMessageGender(state, deviceId));
  const availableGenders = useSelector(state => selectAvailableRoomMessageGenderByLang(state, deviceId));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleMessageGenderChange = (event: React.MouseEvent<HTMLElement>, newGender: EPrerecordedMessageGender) => {
    if (!newGender) return;

    dispatch(setRoomMessageGender({ id: deviceId, gender: newGender }));
  };

  return (
    <ToggleButtonGroup
      value={gender}
      sx={{
        border: ({ palette }) => `1px solid ${palette.grey['700']}`
      }}
      exclusive
      onChange={handleMessageGenderChange}
      aria-label='Gender'
      disabled={disabled}
    >
      {Object.values(EPrerecordedMessageGender).map(item => {
        const disabled = !availableGenders?.includes(item);
        const genderTrans = t(`global.gender.${item}`);

        return (
          <ToggleButton
            aria-label={genderTrans}
            key={item}
            value={`${item}`}
            disabled={disabled}
            sx={{
              fontSize: '12px',
              lineHeight: '1',
              padding: 0,
              height: '25px',
              aspectRatio: 1,
              color: ({ palette }) => palette.common.white,
              '&.Mui-selected': {
                backgroundColor: ({ palette }) => palette.primary.main,
                ':hover': {
                  backgroundColor: ({ palette }) => palette.primary.main
                }
              },
              '&.Mui-disabled': {
                pointerEvents: 'auto',
                color: ({ palette }) => palette.grey['700'],
                ':hover': {
                  backgroundColor: 'unset',
                  cursor: 'auto'
                }
              }
            }}
          >
            <Tooltip
              placement='top-start'
              title={
                disabled
                  ? t('esitter.components.SidePanel.MessagesAccordion.genderNotAvailable', { gender: genderTrans })
                  : genderTrans
              }
            >
              <Box
                component={'span'}
                sx={{
                  padding: '2px 6px',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {t(`global.gender.abbr.${item}`)}
              </Box>
            </Tooltip>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
